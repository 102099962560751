import React from 'react';
import { Dropdown, DropdownProps, Menu, MenuProps, Typography } from 'antd';

import { DropdownItemsType, TToolbarButton } from './types';

interface DropdownMenuProps
  extends Pick<DropdownProps, 'arrow' | 'placement' | 'trigger'> {
  data: TToolbarButton;
  labelsList: DropdownItemsType[];
  onAddItem: (type: any, initialValue: any) => any;
}

export const DropdownMenu: React.FC<DropdownMenuProps> = ({
  arrow = true,
  data,
  labelsList,
  onAddItem,
  placement = 'bottomRight',
  trigger = ['click'],
}) => {
  const items: MenuProps['items'] = labelsList.map(
    (item: DropdownItemsType, index: number) => ({
      label: (
        <div
          key={index + 1}
          onClick={() =>
            onAddItem(data.type, {
              type: item.type,
              [item.type]: item.defaultValue,
            })
          }
        >
          <Typography.Text>{item.label}</Typography.Text>
        </div>
      ),
      key: index + 1,
    })
  );

  return (
    <Dropdown
      arrow={arrow}
      overlay={<Menu items={items} />}
      placement={placement}
      trigger={trigger}
    >
      <div className='toolbar_list-item' title={data.title}>
        {data.icon}
      </div>
    </Dropdown>
  );
};
