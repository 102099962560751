import React from 'react';
import { Content } from 'antd/lib/layout/layout';
import { Card, Col, Row } from 'antd';
import classNames from 'classnames';

import { InputField } from 'components/form/base/InputField';
import { ToolbarButtons } from 'constants/content';

import { TextFormContent } from '../Text';
import { createButton } from '../Wysiwyg/helpers';
import styles from './Reference.module.less';

interface ReferenceProps {
  error: any;
  errorRef: any;
  onCaretInsideText: () => any;
  onCaretNearEmptyLine: (position: number, node: React.ReactNode) => void;
  onChange: (value: any) => void;
  name: string;
  reference: { title: string; subtitle: string };
}

export const Reference = React.forwardRef(
  (
    {
      error,
      errorRef,
      onCaretInsideText,
      onCaretNearEmptyLine,
      onChange,
      name,
      reference,
    }: ReferenceProps,
    ref: any
  ) => {
    const handleChange = (value: string) => {
      onChange({ subtitle: value });
    };

    return (
      <Content style={{ paddingTop: 0, paddingBottom: 0 }}>
        <div ref={ref} tabIndex={0}>
          <Card title='Справка' type='inner'>
            <Row>
              <Col span={24}>
                <div>
                  <InputField
                    name={`${name}.title`}
                    label='Заголовок'
                    placeholder='Введите заголовок'
                    maxLength={150}
                    required
                    showCount
                    value={reference.title}
                  />
                  <div tabIndex={0} className='content_text'>
                    <div className={styles.label}>Подзаголовок</div>
                    <div
                      className={classNames(
                        styles.content,
                        error?.subtitle?.message && styles.content__error
                      )}
                    >
                      <TextFormContent
                        toolbarButtons={[
                          createButton(
                            ToolbarButtons.BOLD,
                            'Полужирный',
                            ToolbarButtons.BOLD
                          ),
                          createButton(
                            ToolbarButtons.ITALIC,
                            'Курсив',
                            ToolbarButtons.ITALIC
                          ),
                          createButton(
                            ToolbarButtons.UNDERLINE,
                            'Подчёркнутый',
                            ToolbarButtons.UNDERLINE
                          ),
                          createButton(
                            ToolbarButtons.STRIKETHROUGH,
                            'Зачёркнутый',
                            ToolbarButtons.STRIKETHROUGH
                          ),
                          createButton(ToolbarButtons.QUOTES),
                          createButton(ToolbarButtons.ANCHOR),
                        ]}
                        errorRef={errorRef}
                        onChange={handleChange}
                        onCaretInsideText={onCaretInsideText}
                        onCaretNearEmptyLine={onCaretNearEmptyLine}
                        placeholder='Описание'
                        value={reference.subtitle}
                      />
                    </div>
                    {Boolean(error?.subtitle?.message) && (
                      <div className={styles.error}>
                        {error.subtitle.message}
                      </div>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </Card>
        </div>
      </Content>
    );
  }
);

Reference.displayName = 'FormContent:Reference';
