import React, { FC, useEffect, useMemo, useState } from 'react';
import { nanoid } from 'nanoid';
import { Modal, Select } from 'antd';

import { CreatePreview } from './components/CreatePreview/CreatePreview';
import {
  MAIN_B2C_ITEM_SETTINGS,
  MAIN_B2C_NUMBER_FACT_ITEM_TYPE,
  MAIN_B2C_PREVIEW_BLOCK_TYPE,
  MAIN_B2C_PREVIEW_ITEM_TYPE,
  MAIN_B2C_THREE_HEADERS_ITEM_TYPE,
} from '../../const';
import { CreateNumberFact } from './components/CreateNumberFact/CreateNumberFact';
import {
  IRow,
  IRowPreviewItem,
  IRowNumberFactItem,
  IRowThreeHeadersItem,
  IRowPreviewBlockItem,
} from '../../types/types';
import { CreateThreeHeaders } from './components/CreateThreeHeaders/CreateThreeHeaders';
import { CreatePreviewBlock } from './components/CreatePreviewBlock/CreatePreviewBlock';

interface ICreateItemModalProps {
  addModalIsOpen: boolean;
  setAddModalIsOpen: (value: boolean) => void;
  row: IRow;
  data: IRow[];
  setData: React.Dispatch<React.SetStateAction<IRow[]>>;
  setModifyingItem: (
    values:
      | IRowPreviewItem
      | IRowNumberFactItem
      | IRowThreeHeadersItem
      | IRowPreviewBlockItem
  ) => void;
  modifyingItem:
    | IRowPreviewItem
    | IRowNumberFactItem
    | IRowThreeHeadersItem
    | IRowPreviewBlockItem;
  setIsSomethingChanged: (value: boolean) => void;
}

export const CreateItemModal: FC<ICreateItemModalProps> = ({
  addModalIsOpen,
  setAddModalIsOpen,
  row,
  setData,
  data,
  modifyingItem,
  setModifyingItem,
  setIsSomethingChanged,
}) => {
  const [selectedType, setSelectedType] =
    useState<keyof typeof MAIN_B2C_ITEM_SETTINGS>(null);

  const handleAddItem = itemData => {
    const itemId = nanoid();
    const updatedData = data.map(item =>
      item.key === row.key
        ? {
            ...item,
            items:
              modifyingItem !== null
                ? item.items.map(el =>
                    el.id === modifyingItem.id ? { ...itemData } : el
                  )
                : [...item.items, { ...itemData, id: itemId, key: itemId }],
          }
        : item
    );

    setData(updatedData);
    setIsSomethingChanged(true);
  };

  const selectOptions = useMemo(() => {
    let isHalfInclude = false;
    let isThirdPartInclude = false;

    const currentWeight = row.items.reduce((acc, curr) => {
      if (curr.weight === 0.5) {
        isHalfInclude = true;
      }
      if (curr.weight === 0.33 || curr.weight === 0.66) {
        isThirdPartInclude = true;
      }
      return acc + curr.weight;
    }, 0);

    const rawOptions = Object.entries(MAIN_B2C_ITEM_SETTINGS);

    const filteredOptions = rawOptions.filter(([key, obj]) => {
      if (1 - currentWeight < obj.weight) return false;
      if (isHalfInclude && obj.weight === 0.33) return false;
      if (isThirdPartInclude && obj.weight === 0.5) return false;
      return true;
    });

    return filteredOptions.map(([key, data]) => {
      return {
        ...data,
        value: key,
        label: data.typeLabel,
      };
    });
  }, [row]);

  const handleSubmit = (
    itemData:
      | IRowPreviewItem
      | IRowNumberFactItem
      | IRowThreeHeadersItem
      | IRowPreviewBlockItem
  ) => {
    setAddModalIsOpen(false);
    handleAddItem(itemData);
    setSelectedType(null);
  };

  const CreateItemModalContent = () => {
    switch (selectedType) {
      case MAIN_B2C_PREVIEW_ITEM_TYPE.WIDE:
      case MAIN_B2C_PREVIEW_ITEM_TYPE.TWO_THIRDS:
      case MAIN_B2C_PREVIEW_ITEM_TYPE.HALF:
      case MAIN_B2C_PREVIEW_ITEM_TYPE.ONE_THIRDS:
        return (
          <CreatePreview
            selectedType={selectedType}
            handleSubmit={handleSubmit}
            modifyingItem={modifyingItem as IRowPreviewItem}
            setModifyingItem={setModifyingItem}
          />
        );
      case MAIN_B2C_PREVIEW_BLOCK_TYPE.RESORTS:
      case MAIN_B2C_PREVIEW_BLOCK_TYPE.ROUTES:
      case MAIN_B2C_PREVIEW_BLOCK_TYPE.ARTICLES:
      case MAIN_B2C_PREVIEW_BLOCK_TYPE.EVENTS:
        return (
          <CreatePreviewBlock
            handleSubmit={handleSubmit}
            modifyingItem={modifyingItem as IRowPreviewBlockItem}
            selectedType={selectedType}
            setModifyingItem={setModifyingItem}
          />
        );

      case MAIN_B2C_NUMBER_FACT_ITEM_TYPE.NUMBER_FACT:
        return (
          <CreateNumberFact
            handleSubmit={handleSubmit}
            modifyingItem={modifyingItem as IRowNumberFactItem}
            setModifyingItem={setModifyingItem}
            selectedType={selectedType}
          />
        );
      case MAIN_B2C_THREE_HEADERS_ITEM_TYPE.THREE_HEADERS:
        return (
          <CreateThreeHeaders
            handleSubmit={handleSubmit}
            modifyingItem={modifyingItem as IRowThreeHeadersItem}
            setModifyingItem={setModifyingItem}
            selectedType={selectedType}
          />
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    if (modifyingItem !== null) {
      setSelectedType(modifyingItem.type);
    } else setSelectedType(null);
  }, [modifyingItem]);

  return (
    <Modal
      title={modifyingItem === null ? 'Добавить элемент' : 'Изменить элемент'}
      open={addModalIsOpen}
      onCancel={() => {
        setAddModalIsOpen(false);
        setModifyingItem(null);
      }}
      width='80vw'
      footer={[]}
      destroyOnClose
    >
      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        {modifyingItem === null && (
          <Select
            options={selectOptions}
            value={selectedType}
            onChange={setSelectedType}
            style={{ width: '300px' }}
            placeholder='Выберите тип добавляемого объекта'
          />
        )}

        {CreateItemModalContent()}
      </div>
    </Modal>
  );
};
