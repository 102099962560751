import React from 'react';
import { useNavigate } from 'react-router';
import { notification } from 'antd';

import { useAppDispatch } from 'store';
import { MainLayout } from 'components/Layout/MainLayout';
import { prepareContent } from 'components/form/Content/utils';
import {
  saveAttractionItem,
  saveAttractionItemAsDraft,
} from 'store/slices/attractions/actions';
import { IAttractionItem } from 'store/slices/attractions/interfaces';
import { StatusesEnum } from 'constants/status';
import { LangEnum } from 'constants/lang';
import { DEFAULT_SEO_STATE } from 'constants/seo';

import { AttractionsForm } from './components/AttractionsForm';

export const Add = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onFinish = (values: IAttractionItem) => {
    setIsLoading(true);
    dispatch(saveAttractionItem(values))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Cущность успешно создана',
        });
        navigate('/attractions');
      })
      .catch(() => setIsLoading(false));
  };

  const onSaveDraft = (values: IAttractionItem) => {
    setIsLoading(true);
    dispatch(saveAttractionItemAsDraft(values))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Cущность успешно создана',
        });
        navigate('/attractions');
      })
      .catch(() => setIsLoading(false));
  };

  const cancelButtonHandler = () => {
    navigate('/attractions');
  };

  return (
    <MainLayout pageTitle='Создание'>
      <AttractionsForm
        onFinish={onFinish}
        onSaveDraft={onSaveDraft}
        cancelButtonHandler={cancelButtonHandler}
        isDraft
        isLoading={isLoading}
        initialValues={{
          ...DEFAULT_SEO_STATE,
          address: {},
          category: null,
          contacts: [],
          content: prepareContent([]),
          gallery: [],
          heroImage: null,
          image: null,
          intro: '',
          isSelection: false,
          lang: LangEnum.ru,
          name: '',
          tags: [],
          status: StatusesEnum.PUBLISHED,
          workTime: {
            fri: { from: null, to: null },
            mon: { from: null, to: null },
            sat: { from: null, to: null },
            sun: { from: null, to: null },
            thu: { from: null, to: null },
            tue: { from: null, to: null },
            wed: { from: null, to: null },
          },
        }}
      />
    </MainLayout>
  );
};
