import React from 'react';
import { Card } from 'antd';
import { Content } from 'antd/lib/layout/layout';

import { TextAreaField } from 'components/form/base/TextAreaField';
import { TImage } from 'types/image';

import { CollageImage } from './CollageImage';
import styles from './Collage.module.less';

const imageErrorHash = {
  source: 'Не заполнена ссылка на источник',
  author: 'Не указан автор изображения',
};

type TProps = {
  onChange: (value: any) => void;
  collage: { images: [TImage, TImage]; text: string };
  error: any;
  errorRef: any;
  name: string;
};

export class FormContentCollage extends React.Component<TProps> {
  static displayName = 'FormContent:Collage';

  static defaultProps = {
    collage: {
      images: [],
      text: '',
    },
    onChange: () => undefined,
  };

  onImageChange = (file, index) => {
    const images = this.props.collage.images.filter(el => el);
    const newImages = [...this.props.collage.images];

    if (images.length < 2 || index < 2) {
      newImages[index] = file;
    }

    this.props.onChange({
      ...this.props.collage,
      images: newImages,
    });
  };

  getImageError = (el, index: number): string | null => {
    if (el && this.props.error?.images?.[index]) {
      return imageErrorHash[Object.keys(this.props.error.images[index])[0]];
    }

    if (!el && this.props.error?.images) {
      return 'Загрузите файл';
    }

    return null;
  };

  render() {
    return (
      <Content style={{ paddingTop: 0, paddingBottom: 0 }}>
        <div ref={this.props.errorRef} tabIndex={0}>
          <Card className={styles.card} title='Коллаж' type='inner'>
            <div className={styles.images}>
              {this.props.collage.images.map((el, index) => {
                return (
                  <div className={styles.imageWrapper} key={`collage-${index}`}>
                    <CollageImage
                      onChange={file => this.onImageChange(file, index)}
                      name='hidden-image'
                      value={el}
                      error={this.getImageError(el, index)}
                    />
                  </div>
                );
              })}
            </div>

            <TextAreaField
              label='Описание изображений'
              name={`${this.props.name}.text`}
              placeholder='Описание'
              showCount
              maxLength={255}
              rows={10}
            />
          </Card>
        </div>
      </Content>
    );
  }
}
