import React from 'react';
import { useNavigate, useParams } from 'react-router';
import { notification } from 'antd';

import { useAppDispatch, useAppSelector } from 'store';
import { IAttractionItem } from 'store/slices/attractions/interfaces';
import { MainLayout } from 'components/Layout/MainLayout';
import {
  loadAttractionItem,
  saveAttractionItem,
  saveAttractionItemAsDraft,
} from 'store/slices/attractions/actions';
import { StatusesEnum } from 'constants/status';

import { AttractionsForm } from './components/AttractionsForm';
import { mapValuesToForm } from './components/formUtils';

export const Edit = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const { item } = useAppSelector(state => state.attractions);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { b2cUrl } = useAppSelector(state => state.configSlice);

  React.useEffect(() => {
    dispatch(loadAttractionItem(id));
  }, []);

  const onFinish = (values: IAttractionItem) => {
    setIsLoading(true);
    dispatch(saveAttractionItem({ ...values, id: item.id }))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Сущность успешно изменена',
        });
        navigate('/attractions');
      })
      .catch(() => setIsLoading(false));
  };

  const onSaveDraft = (values: IAttractionItem) => {
    setIsLoading(true);
    dispatch(saveAttractionItemAsDraft({ ...values, id: item.id }))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Сущность успешно изменена',
        });
        navigate('/attractions');
      })
      .catch(() => setIsLoading(false));
  };

  const cancelButtonHandler = () => {
    navigate('/attractions');
  };

  const getOgSiteName = () => {
    if (!item) return null;

    const sysName = item.sysName || '';
    const linkToB2C =
      item.lang === 'en'
        ? `${b2cUrl}/en/articles/${id}/${sysName}`
        : `${b2cUrl}/articles/${id}/${sysName}`;

    return linkToB2C;
  };

  return (
    <MainLayout pageTitle='Редактирование'>
      {item.id && item.id === Number(id) ? (
        <AttractionsForm
          onFinish={onFinish}
          onSaveDraft={onSaveDraft}
          cancelButtonHandler={cancelButtonHandler}
          isDraft={item.status === StatusesEnum.DRAFT}
          isLoading={isLoading}
          initialValues={{
            ...mapValuesToForm(item),
            ogUrl: getOgSiteName(),
            ogSiteName: 'Посети Кавказ',
            ogType: 'articles',
          }}
          itemId={item.id}
        />
      ) : null}
    </MainLayout>
  );
};
