import React from 'react';
import { Col, Row } from 'antd';

import { LocationSelector } from 'components/form/AddressSelector/LocationSelector';
import { InputField } from 'components/form/base/InputField';
import { DurationField } from 'components/form/DurationField/DurationField';
import { ContentField } from 'components/form/Content/ContentField';

export const ManualPoint = ({ name }) => {
  return (
    <>
      <Row gutter={[16, 0]}>
        <Col flex={1}>
          <InputField
            name={`${name}.routeName`}
            label='Название геометки'
            required
            placeholder='Введите название'
            showCount
            maxLength={255}
          />
        </Col>
      </Row>
      <Row gutter={[24, 0]} align='bottom'>
        <Col xs={{ span: 24 }} md={{ span: 9 }}>
          <DurationField
            name={`${name}.duration`}
            label='Длительность остановки'
          />
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 15 }}>
          <InputField
            name={`${name}.anchorId`}
            label='Имя якорной ссылки'
            placeholder='Введите название'
            showCount
            maxLength={100}
          />
        </Col>
      </Row>
      <LocationSelector showComment={false} name={`${name}.address`} />
      <Row style={{ marginTop: '24px' }}>
        <Col span={24}>
          <ContentField
            name={`${name}.description`}
            label='Описание'
            placeholder='Начните печатать текст'
            withWidgetsDescription
            withPreparingForModeration
            required
            enabledTypes={[]}
          />
        </Col>
      </Row>
    </>
  );
};
