import React, { useMemo, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { Button, Modal } from 'antd';

import { DraggableItem } from '../DraggableItem/DraggableItem';
import { CreateItemModal } from '../CreateItemModal/CreateItemModal';
import { IRow } from '../../types/types';
import styles from '../../styles/MainB2C.module.less';

interface IDraggableRowProps {
  data: IRow[];
  setData: React.Dispatch<React.SetStateAction<IRow[]>>;
  row: IRow;
  index: number;
  moveRow: (fromIndex: number, toIndex: number) => void;
  moveItem: (
    fromRowIndex: number,
    toRowIndex: number,
    fromIndex: number,
    toIndex: number
  ) => void;
  setIsSomethingChanged: (value: boolean) => void;
}

export const DraggableRow: React.FC<IDraggableRowProps> = ({
  row,
  index,
  moveRow,
  moveItem,
  setData,
  data,
  setIsSomethingChanged,
}) => {
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [addModalIsOpen, setAddModalIsOpen] = useState(false);
  const [modifyingItem, setModifyingItem] = useState(null);

  const [, drag] = useDrag({
    type: 'table-row',
    item: { index, dragType: 'table-row', ...row },
  });

  const [, drop] = useDrop({
    accept: ['table-row', 'array-item'],
    drop: (draggedItem: { index: number; dragType: string; key: string }) => {
      if (draggedItem.dragType === 'table-row') {
        if (draggedItem.key !== row.key) {
          moveRow(draggedItem.index, index);
          draggedItem.index = index;
        }
      }
    },
  });

  const canAddMore = useMemo(() => {
    const weight = row.items.reduce((acc, curr) => {
      return acc + curr.weight;
    }, 0);

    return 1 - weight >= 0.33;
  }, [row]);

  const handleDeleteRow = () => {
    try {
      const updatedData = data.filter(el => el.key !== row.key);

      setData(updatedData);
      setIsSomethingChanged(true);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div ref={node => drop(drag(node))} className={styles.draggableRow}>
      <div className={styles.itemsContainer}>
        {row.items.map((item, itemIndex) => (
          <DraggableItem
            row={row}
            key={item.key}
            item={item}
            itemIndex={itemIndex}
            rowIndex={index}
            moveItem={moveItem}
            data={data}
            setData={setData}
            setModifyingItem={setModifyingItem}
            setAddModalIsOpen={setAddModalIsOpen}
            setIsSomethingChanged={setIsSomethingChanged}
          />
        ))}
      </div>
      <div className={styles.buttonsContainer}>
        {canAddMore && (
          <Button
            className={styles.button}
            title='Добавить элемент блока'
            onClick={() => setAddModalIsOpen(true)}
          >
            +
          </Button>
        )}

        <Button
          className={styles.button}
          title='Удалить секцию'
          onClick={() => setDeleteModalIsOpen(true)}
        >
          -
        </Button>
      </div>

      <CreateItemModal
        addModalIsOpen={addModalIsOpen}
        setAddModalIsOpen={setAddModalIsOpen}
        data={data}
        setData={setData}
        row={row}
        modifyingItem={modifyingItem}
        setModifyingItem={setModifyingItem}
        setIsSomethingChanged={setIsSomethingChanged}
      />

      <Modal
        title='Удалить секцию?'
        open={deleteModalIsOpen}
        onCancel={() => setDeleteModalIsOpen(false)}
        width={350}
        footer={[
          <Button key='cancel' onClick={() => setDeleteModalIsOpen(false)}>
            Отмена
          </Button>,
          <Button
            key='submit'
            type='primary'
            onClick={() => {
              setDeleteModalIsOpen(false);
              handleDeleteRow();
            }}
          >
            Удалить
          </Button>,
        ]}
        destroyOnClose
      ></Modal>
    </div>
  );
};
