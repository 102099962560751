import React from 'react';

import { isEmpty } from 'utils/helpers';
import { apiClient } from 'utils/http';
import { urls } from 'store/api';
import { SKFO_REGION_ID, SKFO_REGION_SYSNAME } from 'constants/region';

import { ISelectFieldProps, Select } from '../base/Select';

export type TRegionType = { name: string; fiasId: string; sysName?: string };

interface IProps extends ISelectFieldProps {
  isNewsPage?: boolean;
}

export const CatalogRegionSelect: React.FC<IProps> = ({
  isNewsPage = false,
  ...props
}) => {
  const [regions, setOptions] = React.useState<TRegionType[]>([]);
  const fetchRegions = async () => {
    const res = await apiClient.get(urls.api.catalog.regions.get);
    setOptions(res?.data);
  };

  const options = React.useMemo(() => {
    const regionsFilter = regions.filter(region => {
      if (!isNewsPage) {
        return (
          region.fiasId !== SKFO_REGION_ID ||
          region.sysName !== SKFO_REGION_SYSNAME
        );
      }

      return true;
    });

    return regionsFilter.map(region => ({
      label: region.name,
      value: region.fiasId,
    }));
  }, [regions.length]);

  React.useEffect(() => {
    if (isEmpty(regions)) {
      fetchRegions();
    }
  }, []);

  return <Select {...props} allowClear options={options} />;
};
