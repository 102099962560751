import * as Yup from 'yup';

import { contentSchema } from 'utils/yup';
import {
  mapContent,
  mapContentToForm,
  mapRegion,
  mapSelect,
  mapValuesToRegion,
  mapValuesToSelect,
} from 'utils/mappings';
import { prepareContent } from 'components/form/Content/utils';
import { Statuses, StatusesEnum } from 'constants/status';
import {
  ISupportMeasuresItem,
  TSupportMeasuresItemDraft,
} from 'store/slices/supportMeasures/interfaces';
import { ICatalogData } from 'types/catalogData';
import { LangEnum, LangEnumKeys } from 'constants/lang';
import {
  ContentTypeEnum,
  TTextBlock,
} from 'components/form/Content/contentTypes';
import { stripTagsAndTrim } from 'utils/helpers';

export const checkSKFOSubjectVisibility = (
  supportLevel: ICatalogData | null
) => {
  return !!supportLevel?.name?.toLowerCase().match(/региональные/g);
};

export const mapValuesToForm = values => {
  return {
    ...values,
    lang: values.lang || LangEnum.ru,
    name: values.name || '',
    initiator: values.initiatorData
      ? mapValuesToSelect(values.initiatorData)
      : null,
    supportLevel: values.supportLevelData
      ? mapValuesToSelect(values.supportLevelData)
      : null,
    region: values.region ? mapValuesToRegion(values.region) : null,
    supportPeriod: values.supportPeriodData
      ? mapValuesToSelect(values.supportPeriodData)
      : null,
    industry: values.industryData ? mapValuesToSelect(values.industryData) : [],
    supportFormat: values.supportFormatData
      ? mapValuesToSelect(values.supportFormatData)
      : null,
    supportType: values.supportTypeData
      ? mapValuesToSelect(values.supportTypeData)
      : null,
    supportProblems: values.supportProblemsData
      ? mapValuesToSelect(values.supportProblemsData)
      : [],
    content: prepareContent(mapContentToForm(values.content || [])),
    declarerRequirements: [
      { type: ContentTypeEnum.text, text: values.declarerRequirements },
    ],
    projectRequirements: [
      { type: ContentTypeEnum.text, text: values.projectRequirements },
    ],
    documents: [{ type: ContentTypeEnum.text, text: values.documents }],
    npa: [{ type: ContentTypeEnum.text, text: values.npa }],
    contacts: values.contacts || '',
    externalUrl: values.externalUrl || '',
    status:
      values.status === StatusesEnum.DRAFT
        ? StatusesEnum.PUBLISHED
        : values.status,
  };
};

export const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(1, 'Введите от 1 до 255 символов')
    .max(255, 'Введите от 1 до 255 символов')
    .required(),
  initiator: Yup.mixed().required(),
  supportLevel: Yup.mixed(),
  supportPeriod: Yup.mixed(),
  industry: Yup.array().min(1, 'Выберите хотя бы одну отрасль'),
  supportFormat: Yup.mixed().required(),
  supportType: Yup.mixed().required(),
  supportProblems: Yup.mixed(),
  content: contentSchema,
  declarerRequirements: Yup.array()
    .of(
      Yup.object().shape({
        text: Yup.mixed().test({
          test: text => {
            const filteredText = stripTagsAndTrim(text);

            return filteredText.length <= 4000;
          },
          message: 'Максимальное количество символов - 4000',
        }),
      })
    )
    .required(),
  projectRequirements: Yup.array()
    .of(
      Yup.object().shape({
        text: Yup.mixed().test({
          test: text => {
            const filteredText = stripTagsAndTrim(text);

            return filteredText.length <= 4000;
          },
          message: 'Максимальное количество символов - 4000',
        }),
      })
    )
    .required(),
  documents: Yup.array()
    .of(
      Yup.object().shape({
        text: Yup.mixed().test({
          test: text => {
            const filteredText = stripTagsAndTrim(text);

            return filteredText.length <= 4000;
          },
          message: 'Максимальное количество символов - 4000',
        }),
      })
    )
    .required(),
  npa: Yup.array()
    .of(
      Yup.object().shape({
        text: Yup.mixed().test({
          test: text => {
            const filteredText = stripTagsAndTrim(text);

            return filteredText.length <= 4000;
          },
          message: 'Максимальное количество символов - 4000',
        }),
      })
    )
    .required(),
  contacts: Yup.string().max(1000),
  externalUrl: Yup.string().url(),
  status: Yup.mixed().required(),
});

export const mapValues = (values): ISupportMeasuresItem => {
  return {
    lang: mapSelect(values.lang) as LangEnumKeys,
    name: values.name.trim() || '',
    initiator: values.initiator
      ? (mapSelect(values.initiator) as number)
      : null,
    supportLevel: values.supportLevel
      ? (mapSelect(values.supportLevel) as number)
      : null,
    region:
      values.supportLevel &&
      checkSKFOSubjectVisibility(values.supportLevel) &&
      values.region
        ? mapRegion(values.region)
        : null,
    supportPeriod: values.supportPeriod
      ? (mapSelect(values.supportPeriod) as number)
      : null,
    industry: values.industry ? (mapSelect(values.industry) as number[]) : null,
    supportFormat: values.supportFormat
      ? (mapSelect(values.supportFormat) as number)
      : null,
    supportType: values.supportType
      ? (mapSelect(values.supportType) as number)
      : null,
    supportProblems: values.supportProblems
      ? (mapSelect(values.supportProblems) as number[])
      : null,
    content: mapContent(values.content),
    declarerRequirements: stripTagsAndTrim(values.declarerRequirements[0].text)
      ? (values.declarerRequirements[0] as unknown as TTextBlock).text
      : '',
    projectRequirements: stripTagsAndTrim(values.projectRequirements[0].text)
      ? (values.projectRequirements[0] as unknown as TTextBlock).text
      : '',
    documents: stripTagsAndTrim(values.documents[0].text)
      ? (values.documents[0] as unknown as TTextBlock).text
      : '',
    npa: stripTagsAndTrim(values.npa[0].text)
      ? (values.npa[0] as unknown as TTextBlock).text
      : '',
    contacts: values.contacts || '',
    externalUrl: values.externalUrl || '',
    status: mapSelect(values.status) as Statuses,

    metaTitle: values.metaTitle || '',
    metaDescription: values.metaDescription || '',
    ogTitle: values.ogTitle || '',
    ogDescription: values.ogDescription || '',
    metaKeywords: values.metaKeywords || '',
    ogUrl: values.ogUrl || '',
    ogImage: values.ogImage || '',
  };
};

export const mapValuesToDraft = (values): TSupportMeasuresItemDraft => {
  return {
    lang: mapSelect(values.lang) as LangEnumKeys,
    name: values.name ? values.name : null,
    initiator: values.initiator
      ? (mapSelect(values.initiator) as number)
      : null,
    supportLevel: values.supportLevel
      ? (mapSelect(values.supportLevel) as number)
      : null,
    region:
      values.supportLevel &&
      checkSKFOSubjectVisibility(values.supportLevel) &&
      values.region
        ? mapRegion(values.region)
        : null,
    supportPeriod: values.supportPeriod
      ? (mapSelect(values.supportPeriod) as number)
      : null,
    industry: values.industry ? (mapSelect(values.industry) as number[]) : null,
    supportFormat: values.supportFormat
      ? (mapSelect(values.supportFormat) as number)
      : null,
    supportType: values.supportType
      ? (mapSelect(values.supportType) as number)
      : null,
    supportProblems: values.supportProblems
      ? (mapSelect(values.supportProblems) as number[])
      : null,
    content: values.content?.length ? mapContent(values.content) : [],
    declarerRequirements: (
      values.declarerRequirements[0] as unknown as TTextBlock
    ).text,
    projectRequirements: (
      values.projectRequirements[0] as unknown as TTextBlock
    ).text,
    documents: (values.documents[0] as unknown as TTextBlock).text,
    npa: (values.npa[0] as unknown as TTextBlock).text,
    contacts: values.contacts || '',
    externalUrl: values.externalUrl || '',
    metaTitle: values.metaTitle ? values.metaTitle : null,
    metaDescription: values.metaDescription ? values.metaDescription : null,
    ogTitle: values.ogTitle ? values.ogTitle : null,
    ogDescription: values.ogDescription ? values.ogDescription : null,
    metaKeywords: values.metaKeywords ? values.metaKeywords : null,
  };
};
