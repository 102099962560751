import { TSEO } from 'types/seo';

export const DEFAULT_SEO_STATE: TSEO = {
  metaTitle: '',
  metaDescription: '',
  ogTitle: '',
  ogDescription: '',
  metaKeywords: '',
  ogUrl: '',
  ogType: '',
  ogSiteName: '',
  ogImage: '',
};
