import React, { useEffect, useMemo, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { Button, Image, Modal } from 'antd';
import { SettingOutlined } from '@ant-design/icons';

import { getImageUrl } from '../../../../utils/image';
import type { IRow, IRowItem } from '../../types/types';
import {
  MAIN_B2C_NUMBER_FACT_ITEM_TYPE,
  MAIN_B2C_PREVIEW_BLOCK_TYPE,
  MAIN_B2C_PREVIEW_ITEM_TYPE,
  MAIN_B2C_THREE_HEADERS_ITEM_TYPE,
} from '../../const';
import styles from '../../styles/MainB2C.module.less';

interface IDraggableItemProps {
  data: IRow[];
  setData: React.Dispatch<React.SetStateAction<IRow[]>>;
  row: IRow;
  moveItem: (
    fromRowIndex: number,
    toRowIndex: number,
    fromIndex: number,
    toIndex: number
  ) => void;

  item: IRowItem;
  itemIndex: number;
  rowIndex: number;

  setModifyingItem: (value: IRowItem) => void;
  setAddModalIsOpen: (value: boolean) => void;
  setIsSomethingChanged: (value: boolean) => void;
}

export const DraggableItem: React.FC<IDraggableItemProps> = ({
  item,
  itemIndex,
  rowIndex,
  moveItem,
  row,
  data,
  setData,
  setModifyingItem,
  setAddModalIsOpen,
  setIsSomethingChanged,
}) => {
  const [removeModalIsOpen, setRemoveModalIsOpen] = useState<boolean>(false);
  const [, dragItem] = useDrag({
    type: 'array-item',
    item: {
      rowIndex,
      dragType: 'array-item',
      itemIndex,
      rowId: row.key,
      ...item,
    },
  });

  const [, dropItem] = useDrop({
    accept: ['array-item', 'table-row'],
    drop: (draggedItem: {
      rowIndex: number;
      itemIndex: number;
      dragType: string;
      rowId: string;
      key: string;
    }) => {
      if (draggedItem.dragType === 'array-item') {
        if (draggedItem.rowId === row.key && draggedItem.key !== item.key) {
          moveItem(
            draggedItem.rowIndex,
            rowIndex,
            draggedItem.itemIndex,
            itemIndex
          );
          draggedItem.itemIndex = itemIndex;
        }
      }
    },
  });

  const isThreeHeaders = useMemo(() => {
    return (
      Object.values(MAIN_B2C_THREE_HEADERS_ITEM_TYPE) as string[]
    ).includes(item.type);
  }, [item]);

  const isNumberFact = useMemo(() => {
    return (Object.values(MAIN_B2C_NUMBER_FACT_ITEM_TYPE) as string[]).includes(
      item.type
    );
  }, [item]);

  const isSlider = useMemo(() => {
    return (Object.values(MAIN_B2C_PREVIEW_BLOCK_TYPE) as string[]).includes(
      item.type
    );
  }, [item]);

  const size = useMemo(() => {
    if (item.weight === 1) return 'span 6';
    if (item.weight === 0.66) return 'span 4';
    if (item.weight === 0.5) return 'span 3';
    if (item.weight === 0.33) return 'span 2';
    return 'span 2';
  }, [item]);

  const background = useMemo(() => {
    switch (item.type) {
      case MAIN_B2C_PREVIEW_ITEM_TYPE.WIDE:
      case MAIN_B2C_PREVIEW_ITEM_TYPE.TWO_THIRDS:
      case MAIN_B2C_PREVIEW_ITEM_TYPE.ONE_THIRDS:
      case MAIN_B2C_PREVIEW_ITEM_TYPE.HALF:
        return item.style ? 'rgba(100,100,100,0.25)' : 'rgba(100,100,100,0.1)';
      case MAIN_B2C_NUMBER_FACT_ITEM_TYPE.NUMBER_FACT:
        return 'rgba(1,97,253,0.1)';
      default:
        return 'white';
    }
  }, [item]);

  const imageSrc = useMemo(() => {
    switch (item.type) {
      case MAIN_B2C_PREVIEW_ITEM_TYPE.WIDE:
      case MAIN_B2C_PREVIEW_ITEM_TYPE.TWO_THIRDS:
      case MAIN_B2C_PREVIEW_ITEM_TYPE.ONE_THIRDS:
      case MAIN_B2C_PREVIEW_ITEM_TYPE.HALF:
        return item?.image
          ? getImageUrl(item.image, 102, 102) || '/public/images/no-image.svg'
          : '/public/images/no-image.svg';
      case MAIN_B2C_NUMBER_FACT_ITEM_TYPE.NUMBER_FACT:
        return '/public/images/digit-fact.png';
      default:
        return '/public/images/no-image.svg';
    }
  }, [item]);

  const handleDelete = () => {
    try {
      const updatedData = data.map(dataRow => {
        if (dataRow.key === row.key) {
          return {
            ...dataRow,
            items: dataRow.items.filter(rowItem => rowItem.key !== item.key),
          };
        }
        return dataRow;
      });

      setData(updatedData);
      setIsSomethingChanged(true);
    } catch (e) {
      console.log(e);
    }
  };

  const handleEditItem = () => {
    setModifyingItem(item);
    setAddModalIsOpen(true);
  };

  return (
    <div
      ref={node => dropItem(dragItem(node))}
      className={styles.item}
      style={{
        gridColumn: size,
        backgroundColor: background,
      }}
    >
      <div className={styles.itemButtonsContainer}>
        {!isSlider && (
          <Button
            className={styles.button}
            title='Редактировать'
            onClick={handleEditItem}
          >
            <SettingOutlined
              width='14px'
              height='14px'
              style={{ marginTop: '2px' }}
            />
          </Button>
        )}

        <Button
          className={styles.button}
          title='Удалить секцию'
          onClick={() => setRemoveModalIsOpen(true)}
        >
          -
        </Button>
      </div>

      <span className={styles.typeLabel}>{item.typeLabel}</span>

      {!isThreeHeaders && (
        <Image
          width={102}
          height={102}
          preview={false}
          src={imageSrc}
          fallback='/public/images/no-image.svg'
          style={{ flexShrink: 0 }}
        />
      )}

      <div style={{ gridColumn: isThreeHeaders ? 'span 2' : '' }}>
        <h4 style={{ fontSize: '14px', fontWeight: 500 }}>{item.title}</h4>

        {isNumberFact && item.type === 'number_fact' && (
          <>
            <p>{item.digit}</p>
            <p>{item.unit}</p>
          </>
        )}

        {isThreeHeaders && item.type === 'three_headers' && (
          <>
            {item.previews.map(header => (
              <p key={header.createdAt + header.title}>{header.title}</p>
            ))}
          </>
        )}
      </div>

      <Modal
        title='Удалить?'
        open={removeModalIsOpen}
        onCancel={() => setRemoveModalIsOpen(false)}
        width={350}
        footer={[
          <Button key='cancel' onClick={() => setRemoveModalIsOpen(false)}>
            Отмена
          </Button>,
          <Button
            key='submit'
            type='primary'
            onClick={() => {
              setRemoveModalIsOpen(false);
              handleDelete();
            }}
          >
            Удалить
          </Button>,
        ]}
        destroyOnClose
      />
    </div>
  );
};
