import React, { FC, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import classNames from 'classnames';
import { Button } from 'antd';

import { InputField } from '../../../../../../components/form/base/InputField';
import { IRowNumberFactItem } from '../../../../types/types';
import { MAIN_B2C_ITEM_SETTINGS } from '../../../../const';
import { TextAreaField } from '../../../../../../components/form/base/TextAreaField';
import styles from './CreateNumberFact.module.less';
import yup from '../../../../../../utils/yup';

interface ICreateNumberFactProps {
  handleSubmit: (values: IRowNumberFactItem) => void;
  setModifyingItem: (values: IRowNumberFactItem) => void;
  modifyingItem: IRowNumberFactItem;
  selectedType: keyof typeof MAIN_B2C_ITEM_SETTINGS;
}

export const VALIDATION_SCHEMA = yup.object().shape({
  title: yup.string().required(),
  link: yup.string().url(),
  digit: yup.string().required(),
  unit: yup.string().required(),
  description: yup.string().required(),
});

export const CreateNumberFact: FC<ICreateNumberFactProps> = ({
  handleSubmit,
  setModifyingItem,
  modifyingItem,
  selectedType,
}) => {
  const methods = useForm<IRowNumberFactItem>({
    resolver: yupResolver(VALIDATION_SCHEMA),
    defaultValues: {
      title: 'Цифра дня',
    },
  });

  const { reset } = methods;

  const onSubmit = async values => {
    const reqObj = { ...values };

    const reqBody = {
      ...reqObj,
      status: 'published',
    };
    handleSubmit({
      ...(MAIN_B2C_ITEM_SETTINGS?.[selectedType] || {}),
      ...reqBody,
    });
  };

  const onSubmitHandler = (values: IRowNumberFactItem) => onSubmit(values);

  useEffect(() => {
    if (modifyingItem !== null) reset({ ...modifyingItem });
  }, [modifyingItem]);

  useEffect(() => {
    return () => {
      setModifyingItem(null);
    };
  }, []);

  return (
    <FormProvider {...methods}>
      <form
        className={classNames(
          'ant-form',
          'ant-form-vertical',
          styles.createNumberFactForm
        )}
        onSubmit={methods.handleSubmit(onSubmitHandler)}
      >
        <div className={styles.inputs}>
          <InputField
            name='digit'
            label='Цифра'
            placeholder='Введите число'
            required
          />

          <InputField
            name='unit'
            label='единицы измерения / предмет счета'
            placeholder='Введите единицы измерения'
            required
          />

          <TextAreaField
            name='description'
            label='Текст описание'
            maxLength={1000}
            onBlur={() => methods.clearErrors()}
            placeholder='Введите текст описания'
            required
          />

          <InputField name='link' label='Ссылка' placeholder='Введите ссылка' />

          <InputField
            name='title'
            label='Название блока'
            placeholder='Введите название блока'
            required
          />
        </div>

        <Button
          type='primary'
          htmlType='submit'
          style={{ marginTop: 'auto', alignSelf: 'end' }}
          loading={false}
        >
          Сохранить
        </Button>
      </form>
    </FormProvider>
  );
};
