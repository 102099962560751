import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { DraggableRow } from '../DraggableRow/DraggableRow';
import { IRow } from '../../types/types';
import styles from '../../styles/MainB2C.module.less';

interface IDragAndDropTableProps {
  data: IRow[];
  setData: React.Dispatch<React.SetStateAction<IRow[]>>;
  setIsSomethingChanged: (value: boolean) => void;
}

export const DragAndDropTable: React.FC<IDragAndDropTableProps> = ({
  data,
  setData,
  setIsSomethingChanged,
}) => {
  const moveRow = (fromIndex: number, toIndex: number) => {
    const updatedData = [...data];
    const [movedRow] = updatedData.splice(fromIndex, 1);
    updatedData.splice(toIndex, 0, movedRow);
    setData(updatedData);
    setIsSomethingChanged(true);
  };

  const moveItem = (
    fromRowIndex: number,
    toRowIndex: number,
    fromIndex: number,
    toIndex: number
  ) => {
    const updatedData = [...data];
    const fromRow = updatedData[fromRowIndex];
    const toRow = updatedData[toRowIndex];

    const [movedItem] = fromRow.items.splice(fromIndex, 1);
    if (movedItem) toRow.items.splice(toIndex, 0, movedItem);

    setData(updatedData);
    setIsSomethingChanged(true);
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div className={styles.dndRowsContainer}>
        {data.map((row, index) => (
          <DraggableRow
            key={row.key}
            row={row}
            index={index}
            moveRow={moveRow}
            moveItem={moveItem}
            setData={setData}
            data={data}
            setIsSomethingChanged={setIsSomethingChanged}
          />
        ))}
      </div>
    </DndProvider>
  );
};
