import React from 'react';
import classNames from 'classnames';
import { CSSTransition } from 'react-transition-group';
import { PlusOutlined } from '@ant-design/icons';

import { ContentType, ContentTypeEnum } from '../contentTypes';
import { DropdownMenu } from './DropdownMenu';
import {
  getDropdownArticleItems,
  getDropdownPlaceItems,
  getToolbarButtons,
} from './utils';
import { TToolbarButton } from './types';

const buttons: TToolbarButton[] = getToolbarButtons();
const dropdownArticleItems = getDropdownArticleItems();
const dropdownPlaceItems = getDropdownPlaceItems();

type TProps = {
  onAddItem: (type: any, initialValue: any) => any;
  onOpenClick: () => void;
  open?: boolean;
  enabledTypes: ContentType[];
};

export const Toolbar: React.FC<TProps> = ({
  onAddItem,
  onOpenClick,
  open = false,
  enabledTypes,
}) => {
  const toolbarItems = buttons
    .filter(({ type }) => enabledTypes.includes(type as ContentType))
    .map((data, index) => {
      if (
        data.type === ContentTypeEnum.widget &&
        (data.initialValue?.type === 'place' ||
          data.initialValue?.type === 'article')
      ) {
        const items =
          data.initialValue?.type === 'place'
            ? dropdownPlaceItems
            : dropdownArticleItems;
        return (
          <DropdownMenu
            data={data}
            key={index}
            labelsList={items}
            onAddItem={onAddItem}
          />
        );
      }

      return (
        <button
          key={index}
          type='button'
          className='toolbar_list-item'
          title={data.title}
          onClick={() => onAddItem(data.type, data.initialValue)}
        >
          {data.icon}
        </button>
      );
    });

  return (
    <div className='toolbar'>
      <button type='button' className='toolbar_close' onClick={onOpenClick}>
        <div
          className={classNames('toolbar_icon', {
            toolbar_icon__rotated: open,
          })}
        >
          <PlusOutlined style={{ fontSize: '20px' }} />
        </div>
      </button>

      <CSSTransition
        mountOnEnter
        unmountOnExit
        in={open}
        timeout={640}
        classNames={{
          enter: 'toolbar_list__enter',
          enterActive: 'toolbar_list__enter-active',
          exit: 'toolbar_list__exit',
          exitActive: 'toolbar_list__exit-active',
        }}
      >
        <div className='toolbar_list'>{toolbarItems}</div>
      </CSSTransition>
    </div>
  );
};
