import React from 'react';
import { useNavigate } from 'react-router';
import { notification } from 'antd';

import { useAppDispatch } from 'store';
import { MainLayout } from 'components/Layout/MainLayout';
import { prepareContent } from 'components/form/Content/utils';
import {
  saveHotelsItem,
  saveHotelsItemAsDraft,
} from 'store/slices/hotels/actions';
import { IHotelsItem, IHotelsDraftItem } from 'store/slices/hotels/interfaces';
import { StatusesEnum } from 'constants/status';
import { LangEnum } from 'constants/lang';
import { DEFAULT_SEO_STATE } from 'constants/seo';

import { HotelsForm, hotelStars } from './components/HotelsForm/HotelsForm';

export const Add = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onFinish = (values: IHotelsItem) => {
    setIsLoading(true);
    dispatch(saveHotelsItem(values))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Cущность успешно создана',
        });
        navigate('/hotels');
      })
      .catch(() => setIsLoading(false));
  };

  const onSaveDraft = (values: IHotelsDraftItem) => {
    setIsLoading(true);
    dispatch(saveHotelsItemAsDraft(values))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Cущность успешно создана',
        });
        navigate('/hotels');
      })
      .catch(() => setIsLoading(false));
  };

  const cancelButtonHandler = () => {
    navigate('/hotels');
  };

  return (
    <MainLayout pageTitle='Создание'>
      <HotelsForm
        onFinish={onFinish}
        onSaveDraft={onSaveDraft}
        cancelButtonHandler={cancelButtonHandler}
        isDraft
        isLoading={isLoading}
        initialValues={{
          ...DEFAULT_SEO_STATE,
          address: {},
          arrivalTime: null,
          category: null,
          comfortGroups: [],
          contacts: [],
          content: prepareContent([]),
          departureTime: null,
          externalUrl: '',
          features: [],
          gallery: [],
          heroImage: null,
          hotelComforts: [],
          image: null,
          intro: '',
          lang: LangEnum.ru,
          name: '',
          price: null,
          rating: null,
          shortDescription: '',
          status: StatusesEnum.PUBLISHED,
          starRating: hotelStars[0],
        }}
      />
    </MainLayout>
  );
};
