import * as Yup from 'yup';

import { contentSchema, imageSchema, seoValidationSchema } from 'utils/yup';
import {
  mapContent,
  mapContentToForm,
  mapGallery,
  mapImage, mapImageForSeo,
  mapValuesToRegion
} from "utils/mappings";
import { prepareContent } from 'components/form/Content/utils';
import {
  IRegionsItem,
  IRegionsItemResponse,
} from 'store/slices/regions/interfaces';
import { tutuWidgetName } from 'types/tutu';

const mapGetThere = getThereValues => {
  return getThereValues.map(item => ({
    id: item.selectedWay.value,
    description: item.description,
    tutuWidget: item.selectedWayTutu?.value,
  }));
};

const mapGetThereToForm = (getThereDataValues, getThereValues) => {
  return getThereDataValues.map(item => {
    const tutu = getThereValues.find(elem => item.id === elem.id).tutuWidget;

    return {
      selectedWay: {
        ...item,
        value: item.id,
        label: item.name,
      },
      selectedWayTutu: tutu
        ? {
            value: tutu,
            label: tutuWidgetName[tutu],
          }
        : null,
      description: getThereValues.find(elem => item.id === elem.id).description,
    };
  });
};

const mapArticles = articlesValues => {
  return articlesValues.map(article => article.id);
};

export const mapValuesToForm = (values): IRegionsItemResponse => {
  return {
    ...values,
    region:
      values.fiasId && values.name
        ? mapValuesToRegion({ name: values.name, fiasId: values.fiasId })
        : null,
    shortDescription: values.shortDescription || '',
    cover: values.cover ? values.cover : {},
    content: prepareContent(mapContentToForm(values.content || [])),
    getThere: values.getThereData
      ? mapGetThereToForm(values.getThereData, values.getThere)
      : [],
    gallery: values.gallery ? values.gallery : [],
    articles: values.articlesData || [],
    metaTitle: values.metaTitle || '',
    metaDescription: values.metaDescription || '',
    ogTitle: values.ogTitle || '',
    ogDescription: values.ogDescription || '',
    metaKeywords: values.metaKeywords || '',
    ogType: values.ogType || null,
    ogUrl: values.ogUrl || null,
    ogSiteName: values.ogSiteName || null,
  };
};

export const validationSchema = Yup.object()
  .shape({
    image: imageSchema,
    ogTitle: Yup.string()
      .trim()
      .min(1, 'Введите от 1 до 300 символов')
      .max(300, 'Введите от 1 до 300 символов')
      .required(),
    ogDescription: Yup.string()
      .trim()
      .min(1, 'Введите от 1 до 300 символов')
      .max(300, 'Введите от 1 до 300 символов')
      .required(),
    metaTitle: Yup.string()
      .trim()
      .min(1, 'Введите от 1 до 300 символов')
      .max(300, 'Введите от 1 до 300 символов')
      .required(),
    metaDescription: Yup.string()
      .trim()
      .min(1, 'Введите от 1 до 300 символов')
      .max(300, 'Введите от 1 до 300 символов')
      .required(),
    ogUrl: Yup.string()
      .trim()
      .min(1, 'Введите от 1 до 300 символов')
      .max(300, 'Введите от 1 до 300 символов')
      .required(),
    ogSiteName: Yup.string()
      .trim()
      .min(1, 'Введите от 1 до 300 символов')
      .max(300, 'Введите от 1 до 300 символов')
      .required(),
    shortDescription: Yup.mixed(),
    cover: imageSchema,
    content: contentSchema,
    getThere: Yup.array().of(
      Yup.object().shape({
        selectedWay: Yup.mixed().required(),
        description: Yup.mixed(),
      })
    ),
    gallery: Yup.array().of(imageSchema),
    articles: Yup.mixed(),
  })
  .concat(seoValidationSchema);

export const mapValues = (values): IRegionsItem => {
  return {
    image: mapImage(values.image),
    shortDescription: values.shortDescription ? values.shortDescription : null,
    cover: mapImage(values.cover),
    content: mapContent(values.content),
    getThere: values.getThere?.length ? mapGetThere(values.getThere) : [],
    gallery: values.gallery ? mapGallery(values.gallery) : [],
    articles: values.articles.length ? mapArticles(values.articles) : [],
    metaTitle: values.metaTitle || null,
    metaDescription: values.metaDescription || null,
    ogTitle: values.ogTitle || null,
    ogDescription: values.ogDescription || null,
    metaKeywords: values.metaKeywords || null,
    ogImage: values.ogImage ? mapImageForSeo(mapImage(values.ogImage)) : null,
    ogType: values.ogType || null,
    ogUrl: values.ogUrl || null,
    ogSiteName: values.ogSiteName || null,
  };
};
