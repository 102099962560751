import React from 'react';
import { Card, Col, Row } from 'antd';
import { Content } from 'antd/lib/layout/layout';

import { LocationSelector } from 'components/form/AddressSelector/LocationSelector';

import { InputField } from '../../base/InputField';
import styles from './Manual.module.less';
type TProps = {
  name: string;
  widget: any;
};
export class Manual extends React.Component<TProps> {
  static defaultProps = {
    name: '',
  };

  render(): React.ReactNode {
    return (
      <Content
        className={styles.content}
        style={{ paddingTop: 0, paddingBottom: 0 }}
      >
        <Card title='Геометка' type='inner'>
          <Row gutter={[16, 0]}>
            <Col flex={1}>
              <InputField
                name={`${this.props.name}.title`}
                label='Название геометки'
                required
                placeholder='Введите название'
                showCount
                maxLength={255}
              />
            </Col>
          </Row>
          <LocationSelector
            name={`${this.props.name}.address`}
            showComment={false}
          />
        </Card>
      </Content>
    );
  }
}
