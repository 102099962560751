import * as Yup from 'yup';

import {
  audioSchema,
  contentSchema,
  imageSchema,
  routePointsSchema,
  seoValidationSchema,
} from 'utils/yup';
import {
  mapAddress,
  mapContent,
  mapContentToForm,
  mapImage,
  mapImageForSeo,
  mapRegion,
  mapSelect,
  mapValuesToAddress,
  mapValuesToRegion,
  mapValuesToSelect,
} from 'utils/mappings';
import { prepareContent } from 'components/form/Content/utils';
import {
  ITouristRoutesDraftItem,
  ITouristRoutesItem,
} from 'store/slices/touristRoutes/interfaces';
import { Statuses, StatusesEnum } from 'constants/status';
import { LangEnum, LangEnumKeys } from 'constants/lang';

export const mapValuesToForm = values => {
  return {
    ...values,
    content: prepareContent(mapContentToForm(values.content || [])),
    externalUrl: values.externalUrl || '',
    intro: values?.intro || '',
    lang: values.lang || LangEnum.ru,
    metaDescription: values.metaDescription || '',
    metaKeywords: values.metaKeywords || '',
    metaTitle: values.metaTitle || '',
    ogDescription: values.ogDescription || '',
    ogTitle: values.ogTitle || '',
    routeType: values.routeTypeData
      ? mapValuesToSelect(values.routeTypeData)
      : null,
    routeLevel: values.routeLevelData
      ? mapValuesToSelect(values.routeLevelData)
      : null,
    points: mapValuesToPoints(values.points),
    polyline: values.polylines,
    region: values.region ? mapValuesToRegion(values.region) : null,
    sortPriorityMainPage: values.sortPriority || null,
    sortPriority: values.sortPriority || null,
    status: values.status === StatusesEnum.DRAFT ? null : values.status,
    tags: values.tagsData ? mapValuesToSelect(values.tagsData) : [],
    ogType: values.ogType || null,
    ogUrl: values.ogUrl || null,
    ogSiteName: values.ogSiteName || null,
  };
};

export const validationSchema = Yup.object()
  .shape({
    audio: audioSchema.nullable(),
    content: contentSchema,
    days: Yup.number().required(),
    duration: Yup.string().required(),
    externalUrl: Yup.string().url(),
    image: imageSchema,
    intro: Yup.string()
      .notRequired()
      .trim()
      .max(700, 'Введите не более 600 символов'),
    name: Yup.string()
      .trim()
      .min(1, 'Введите от 1 до 255 символов')
      .max(255, 'Введите от 1 до 255 символов')
      .required(),
    points: routePointsSchema,
    region: Yup.mixed().required(),
    routeLevel: Yup.mixed(),
    routeType: Yup.mixed().required(),
    shortDescription: Yup.string()
      .trim()
      .min(1, 'Введите от 1 до 300 символов')
      .max(300, 'Введите от 1 до 300 символов')
      .required(),
    status: Yup.mixed().required(),
    tags: Yup.array().max(10, 'Выберите не более ${max} тегов'),
    ogTitle: Yup.string()
      .trim()
      .min(1, 'Введите от 1 до 300 символов')
      .max(300, 'Введите от 1 до 300 символов')
      .required(),
    ogDescription: Yup.string()
      .trim()
      .min(1, 'Введите от 1 до 300 символов')
      .max(300, 'Введите от 1 до 300 символов')
      .required(),
    metaTitle: Yup.string()
      .trim()
      .min(1, 'Введите от 1 до 300 символов')
      .max(300, 'Введите от 1 до 300 символов')
      .required(),
    metaDescription: Yup.string()
      .trim()
      .min(1, 'Введите от 1 до 300 символов')
      .max(300, 'Введите от 1 до 300 символов')
      .required(),
    ogUrl: Yup.string()
      .trim()
      .min(1, 'Введите от 1 до 300 символов')
      .max(300, 'Введите от 1 до 300 символов')
      .required(),
    ogSiteName: Yup.string()
      .trim()
      .min(1, 'Введите от 1 до 300 символов')
      .max(300, 'Введите от 1 до 300 символов')
      .required(),
  })
  .concat(seoValidationSchema);

export const mapValues = (values): ITouristRoutesItem => {
  return {
    audio: values.audio?.path ? values.audio : null,
    content: mapContent(values.content),
    days: values.days || values.days === 0 ? Number(values.days) : null,
    duration: values.duration || null,
    externalUrl: values.externalUrl || null,
    heroImage: null,
    image: mapImage(values.image),
    intro: values?.intro?.trim() || '',
    lang: mapSelect(values.lang) as LangEnumKeys,
    metaDescription: values.metaDescription || null,
    metaKeywords: values.metaKeywords || null,
    metaTitle: values.metaTitle || null,
    name: values.name.trim(),
    ogDescription: values.ogDescription || null,
    ogTitle: values.ogTitle || null,
    points: mapPoints(values.points),
    polylines:
      values.polyline &&
      values.polyline.map(item => {
        return [String(item[0]), String(item[1])];
      }),
    region: mapRegion(values.region),
    routeLevel: values.routeLevel ? Number(mapSelect(values.routeLevel)) : null,
    routeType: values.routeType ? Number(mapSelect(values.routeType)) : null,
    shortDescription: values.shortDescription.trim(),
    sortPriority: values.sortPriority || null,
    sortPriorityMainPage: values.sortPriority || null,
    status: mapSelect(values.status) as Statuses,
    tags: mapSelect(values.tags) as number[],
    ogImage: values.ogImage ? mapImageForSeo(mapImage(values.ogImage)) : null,
    ogType: values.ogType || null,
    ogUrl: values.ogUrl || null,
    ogSiteName: values.ogSiteName || null,
  };
};

export const mapValuesToDraft = (values): ITouristRoutesDraftItem => {
  return {
    audio: values.audio?.path ? values.audio : null,
    content: values.content?.length ? mapContent(values.content) : [],
    days: values.days ? Number(values.days) : null,
    duration: values.duration || null,
    externalUrl: values.externalUrl || null,
    heroImage: null,
    image: values.image ? mapImage(values.image) : null,
    intro: values?.intro?.trim() || '',
    lang: mapSelect(values.lang) as LangEnumKeys,
    metaDescription: values.metaDescription || null,
    metaKeywords: values.metaKeywords || null,
    metaTitle: values.metaTitle || null,
    name: values.name?.trim() || null,
    ogDescription: values.ogDescription || null,
    ogTitle: values.ogTitle || null,
    points: values.points?.length ? mapPoints(values.points) : [],
    polylines:
      (values.polyline &&
        values.polyline.map(item => {
          return [String(item[0]), String(item[1])];
        })) ||
      null,
    region: values.region ? mapRegion(values.region) : null,
    routeType: values.routeType ? Number(mapSelect(values.routeType)) : null,
    routeLevel: values.routeLevel ? Number(mapSelect(values.routeLevel)) : null,
    sortPriority: values.sortPriority || null,
    sortPriorityMainPage: values.sortPriority || null,
    shortDescription: values.shortDescription?.trim() || null,
    tags: values.tags?.length ? (mapSelect(values.tags) as number[]) : [],
    ogImage: values.ogImage ? mapImageForSeo(mapImage(values.ogImage)) : null,
    ogType: values.ogType || null,
    ogUrl: values.ogUrl || null,
    ogSiteName: values.ogSiteName || null,
  };
};

const mapPoints = points => {
  return points.map(item => {
    if (item.type === 'manual') {
      return {
        type: 'geoPosition',
        geoPosition: {
          name: item.routeName,
          shortDescription: item.shortDescription,
          description: mapContent(item.description),
          address: mapAddress(item.address),
          image: null,
          duration: item.duration,
          audio: item.audio?.path ? item.audio : null,
          anchorId: item?.anchorId || '',
        },
      };
    }
    if (item.type === 'place') {
      return {
        type: 'widget',
        widget: {
          type: mapSelect(item.placeType),
          id: mapSelect(item.place),
          duration: item.duration,
          description: mapContent(item.description),
          audio: item.audio?.path ? item.audio : null,
          anchorId: item?.anchorId || '',
        },
      };
    }
  });
};

const mapValuesToPoints = points => {
  return points.map((item, index) => {
    if (item.type === 'geoPosition') {
      const value = item.geoPosition;
      return {
        id: index,
        type: 'manual',
        routeName: value.name,
        description: prepareContent(value.description || []),
        address: value.address ? mapValuesToAddress(value.address) : {},
        image: null,
        duration: value.duration,
        anchorId: value?.anchorId?.trim() || '',
      };
    }
    if (item.type === 'widget') {
      const value = item.widget;
      return {
        id: index,
        type: 'place',
        placeType: item.widget.type,
        place: mapValuesToSelect(value.widgetData),
        duration: value.duration,
        description: prepareContent(value.description || []),
        audio: value.audio,
        anchorId: value?.anchorId?.trim() || '',
      };
    }
  });
};
