import React from 'react';
import { Card } from 'antd';
import { Content } from 'antd/lib/layout/layout';

import { SeparatorImage } from './SeparatorImage';
import { SeparatorDropdown } from './SeparatorDropdown';

type TProps = {
  onChange: (value: any) => void;
  separator: any;
  name: string;
};

export class Separator extends React.Component<TProps> {
  static displayName = 'FormContent:Separator';

  static defaultProps = {
    separator: 'mountain',

    onChange: () => undefined,
  };

  onAddItem = (value: 'mountain' | 'triangles') => {
    this.props.onChange(value);
  };

  render(): React.ReactNode {
    return (
      <Content style={{ paddingTop: 0, paddingBottom: 0 }}>
        <Card
          title='Разделитель'
          extra={
            <SeparatorDropdown
              value={this.props.separator}
              onAddItem={this.onAddItem}
            />
          }
          type='inner'
        >
          <>
            <SeparatorImage variant={this.props.separator} />

            <input
              name={this.props.name}
              type='hidden'
              defaultValue={this.props.separator}
            />
          </>
        </Card>
      </Content>
    );
  }
}
