export const MAIN_B2C_PREVIEW_ITEM_TYPE = {
  WIDE: 'wide',
  TWO_THIRDS: 'two_thirds',
  HALF: 'half',
  ONE_THIRDS: 'one_thirds',
} as const;

export const MAIN_B2C_PREVIEW_BLOCK_TYPE = {
  EVENTS: 'events',
  ARTICLES: 'articles',
  ROUTES: 'routes',
  RESORTS: 'resorts',
} as const;

export const MAIN_B2C_TABLE_NAMES = {
  routes: 'touristRoutes',
} as const;

export const MAIN_B2C_TABLE_LABELS = {
  news: 'Новости',
  articles: 'Статьи',
  attractions: 'Места',
  events: 'События',
  routes: 'Маршруты',
} as const;

export const MAIN_B2C_NUMBER_FACT_ITEM_TYPE = {
  NUMBER_FACT: 'number_fact',
} as const;

export const MAIN_B2C_THREE_HEADERS_ITEM_TYPE = {
  THREE_HEADERS: 'three_headers',
} as const;

// события, ликбез, маршруты, курорты
// events, educational program, routes, resorts

export const MAIN_B2C_ITEM_SETTINGS = {
  wide: {
    weight: 1,
    typeLabel: 'Превью широкое',
    type: MAIN_B2C_PREVIEW_ITEM_TYPE.WIDE,
  },
  two_thirds: {
    weight: 0.66,
    typeLabel: 'Превью 2/3',
    type: MAIN_B2C_PREVIEW_ITEM_TYPE.TWO_THIRDS,
  },
  half: {
    weight: 0.5,
    typeLabel: 'Превью 1/2',
    type: MAIN_B2C_PREVIEW_ITEM_TYPE.HALF,
  },
  one_thirds: {
    weight: 0.33,
    typeLabel: 'Превью 1/3',
    type: MAIN_B2C_PREVIEW_ITEM_TYPE.ONE_THIRDS,
  },
  number_fact: {
    weight: 0.5,
    typeLabel: 'Блок «Цифрофакт»',
    type: MAIN_B2C_NUMBER_FACT_ITEM_TYPE.NUMBER_FACT,
  },
  three_headers: {
    weight: 0.5,
    typeLabel: 'Блок «Три заголовка»',
    type: MAIN_B2C_THREE_HEADERS_ITEM_TYPE.THREE_HEADERS,
  },
  events: {
    weight: 1,
    typeLabel: 'Блок «События»',
    type: MAIN_B2C_PREVIEW_BLOCK_TYPE.EVENTS,
    title: 'События',
  },
  articles: {
    weight: 1,
    typeLabel: 'Блок «Ликбез»',
    type: MAIN_B2C_PREVIEW_BLOCK_TYPE.ARTICLES,
    title: 'Ликбез',
  },
  routes: {
    weight: 1,
    typeLabel: 'Блок «Маршруты»',
    type: MAIN_B2C_PREVIEW_BLOCK_TYPE.ROUTES,
    title: 'Маршруты',
  },
  resorts: {
    weight: 1,
    typeLabel: 'Блок «Курорты»',
    type: MAIN_B2C_PREVIEW_BLOCK_TYPE.RESORTS,
    title: 'Курорты',
  },
} as const;

export const ENTITY_TYPE = {
  PREVIEW: 'preview',
  PREVIEW_BLOCK: 'previewBlock', // свайпер
  THREE_TITLES: 'threeTitles',
  DIGIT_FACTS: 'digitFacts',
} as const;
