import React from 'react';

export const MountainIcon: React.FC = () => {
  return (
    <svg
      width='41'
      height='23'
      viewBox='0 0 41 23'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g opacity='0.25'>
        <path
          d='M8.21826 21.6931H38.7137L23.466 1.69336L16.4514 10.9324'
          stroke='#0161FD'
          strokeWidth='2'
          strokeMiterlimit='10'
        />
        <path
          d='M13.7726 7.56299L3 21.6932H24.5451L13.7726 7.56299Z'
          stroke='#0161FD'
          strokeWidth='2'
          strokeMiterlimit='10'
        />
      </g>
    </svg>
  );
};
