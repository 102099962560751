import React, { ChangeEvent, FC, FormEvent, useMemo, useState } from 'react';
import { Button, Input } from 'antd';
import { LoadingOutlined, SearchOutlined } from '@ant-design/icons';

import { IRowThreeHeadersItem } from '../../../../types/types';
import { debounce } from '../../../../../../utils/helpers';
import { urls } from '../../../../../../store/api';
import { apiClient } from '../../../../../../utils/http';
import styles from '../CreateThreeHeaders/CreateThreeHeaders.module.less';

interface ICatalogListProps {
  allCatalogs: any;
  handleSetHeader: (headerNumber: number, catalog: any) => void;
  number: number;
  isLoading: boolean;
}

export const CatalogList: FC<ICatalogListProps> = ({
  allCatalogs,
  handleSetHeader,
  number,
  isLoading,
}) => {
  const [searchLine, setSearchLine] = useState<string>('');
  const [catalogsData, setCatalogsData] = useState([]);
  const [arrTrigger, setArrTrigger] = useState<boolean>(false);
  const [catalogsDataIsLoading, setCatalogsDataIsLoading] =
    useState<boolean>(false);

  const getCatalogsData = debounce(async () => {
    setCatalogsDataIsLoading(true);
    const searchParams = new URLSearchParams();
    searchParams.append('searchLine', searchLine);

    try {
      const { data } = await apiClient.get(
        `${urls.api.previewB2C.materials.get}/?${searchParams.toString()}`
      );

      setCatalogsData(data);
    } catch (e) {
      setCatalogsData([]);
      console.error(e);
    } finally {
      setCatalogsDataIsLoading(false);
    }
  }, 300);

  const dataArr = useMemo(
    () => (searchLine === '' ? allCatalogs : catalogsData),
    [allCatalogs, catalogsData, arrTrigger]
  );

  const handleAddHeading = catalog => {
    handleSetHeader(number, catalog);
  };

  const handleSearch = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setArrTrigger(!arrTrigger);
    if (searchLine !== '') getCatalogsData(searchLine);
  };

  const handleSearchInput = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchLine(event.target.value);
  };

  return (
    <div className={styles.searchContainer}>
      <form onSubmit={handleSearch}>
        <Input
          onChange={handleSearchInput}
          placeholder='Поиск по названию'
          maxLength={255}
          addonAfter={
            <Button
              htmlType='submit'
              style={{ border: 'none', padding: '0', height: 'auto' }}
              disabled={isLoading || catalogsDataIsLoading}
            >
              <SearchOutlined />
            </Button>
          }
        />
      </form>

      <div className={styles.catalogData}>
        {isLoading || catalogsDataIsLoading ? (
          <LoadingOutlined />
        ) : (
          dataArr?.map(el => (
            <div className={styles.catalogDataItem} key={el.createdAt}>
              <h3 className={styles.catalogDataItemName}>{el.name}</h3>
              <Button
                className={styles.catalogDataItemButton}
                onClick={() => handleAddHeading(el)}
              >
                Выбрать
              </Button>
            </div>
          ))
        )}
      </div>
    </div>
  );
};
