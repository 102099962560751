import React from 'react';
import classNames from 'classnames';
import { Button, Tooltip } from 'antd';
import * as deepEqual from 'fast-deep-equal/es6/react';

import { Dropzone } from '../../Dropzone/Dropzone';
import { ImagePreview } from '../../ImagePreview/ImagePreview';
import styles from './Collage.module.less';

type TProps = {
  name: string;
  value: any;
  onChange: (value: any) => void;
  children: React.ReactNode;
  error?: string;
};

export class CollageImageUpload extends React.Component<TProps> {
  static defaultProps = {
    onChange: () => undefined,
    value: null,
    children: null,
    imageModifiers: null,
  };
  dropzone;

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !deepEqual.default(this.props, nextProps) ||
      !deepEqual.default(this.state, nextState)
    );
  }

  onUploadClick = () => {
    this.dropzone.open();
  };

  refDropzone = el => {
    this.dropzone = el;
  };

  render() {
    return (
      <Tooltip title={this.props.error}>
        <div
          className={classNames(styles.collageErrorWrapper, {
            [styles.error]: this.props.error,
          })}
        >
          <Dropzone
            className={classNames(styles.dropzone, {
              [styles.dropzonEerror]: this.props.error,
            })}
            name={`${this.props.name}-dropzone`}
            onDrop={this.props.onChange}
            ref={this.refDropzone}
            disableClick
          >
            <div className={styles.image}>
              <ImagePreview
                value={this.props.value || '/public/images/gallery/preview.jpg'}
              />
            </div>

            <div className={styles.uploadArea}>
              <Button onClick={this.onUploadClick}>Загрузить фотографию</Button>
            </div>

            {this.props.children}
          </Dropzone>
        </div>
      </Tooltip>
    );
  }
}
