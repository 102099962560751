import React from 'react';
import { useNavigate, useParams } from 'react-router';
import { notification } from 'antd';

import { useAppDispatch, useAppSelector } from 'store';
import {
  IRestaurantsDraftItem,
  IRestaurantsItem,
} from 'store/slices/restaurants/interfaces';
import { MainLayout } from 'components/Layout/MainLayout';
import {
  loadRestaurantsItem,
  saveRestaurantsItem,
  saveRestaurantsItemAsDraft,
} from 'store/slices/restaurants/actions';
import { StatusesEnum } from 'constants/status';

import { RestaurantsForm } from './components/RestaurantsForm';
import { mapValuesToForm } from './components/formUtils';

export const Edit = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const { item } = useAppSelector(state => state.restaurants);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { b2cUrl } = useAppSelector(state => state.configSlice);

  React.useEffect(() => {
    dispatch(loadRestaurantsItem(id));
  }, []);

  const onFinish = (values: IRestaurantsItem) => {
    setIsLoading(true);
    dispatch(saveRestaurantsItem({ ...values, id: item.id }))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Сущность успешно изменена',
        });
        navigate('/restaurants');
      })
      .catch(() => setIsLoading(false));
  };

  const onSaveDraft = (values: IRestaurantsDraftItem) => {
    setIsLoading(true);
    dispatch(saveRestaurantsItemAsDraft({ ...values, id: item.id }))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Сущность успешно изменена',
        });
        navigate('/restaurants');
      })
      .catch(() => setIsLoading(false));
  };

  const cancelButtonHandler = () => {
    navigate('/restaurants');
  };

  const getOgSiteName = () => {
    if (!item) return null;

    const sysName = item.sysName || '';
    const linkToB2C =
      item.lang === 'en'
        ? `${b2cUrl}/en/bars-and-restaurants/${id}/${sysName}`
        : `${b2cUrl}/bars-and-restaurants/${id}/${sysName}`;

    return linkToB2C;
  };

  return (
    <MainLayout pageTitle='Редактирование'>
      {item.id && item.id === Number(id) ? (
        <RestaurantsForm
          onFinish={onFinish}
          onSaveDraft={onSaveDraft}
          cancelButtonHandler={cancelButtonHandler}
          isDraft={item.status === StatusesEnum.DRAFT}
          isLoading={isLoading}
          initialValues={{
            ...mapValuesToForm(item),
            ogUrl: getOgSiteName(),
            ogSiteName: 'Посети Кавказ',
            ogType: 'bars-and-restaurants',
          }}
        />
      ) : null}
    </MainLayout>
  );
};
