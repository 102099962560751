import * as Yup from 'yup';

import {
  contactsSchema,
  contentSchema,
  imageSchema,
  locationSchema,
  scheduleItemSchema,
  seoValidationSchema,
} from 'utils/yup';
import {
  mapAddress,
  mapContacts,
  mapContent,
  mapContentToForm,
  mapGallery,
  mapImage, mapImageForSeo,
  mapSelect,
  mapValuesToAddress,
  mapValuesToSelect
} from "utils/mappings";
import { prepareContent } from 'components/form/Content/utils';
import { Statuses, StatusesEnum } from 'constants/status';
import {
  IRestaurantsDraftItem,
  IRestaurantsItem,
} from 'store/slices/restaurants/interfaces';
import { LangEnum, LangEnumKeys } from 'constants/lang';

export const mapValuesToForm = values => {
  return {
    ...values,
    address: values.address ? mapValuesToAddress(values.address) : {},
    content: prepareContent(mapContentToForm(values.content || [])),
    features: values.featuresData ? mapValuesToSelect(values.featuresData) : [],
    foods: values.foodsData ? mapValuesToSelect(values.foodsData) : [],
    intro: values?.intro || '',
    lang: values.lang || LangEnum.ru,
    metaDescription: values.metaDescription || '',
    metaKeywords: values.metaKeywords || '',
    metaTitle: values.metaTitle || '',
    name: values.name || '',
    ogDescription: values.ogDescription || '',
    ogTitle: values.ogTitle || '',
    restaurantTypes: values.restaurantTypesData
      ? mapValuesToSelect(values.restaurantTypesData)
      : [],
    shortDescription: values.shortDescription || '',
    status:
      values.status === StatusesEnum.DRAFT
        ? StatusesEnum.PUBLISHED
        : values.status,
    ogType: values.ogType || null,
    ogUrl: values.ogUrl || null,
    ogSiteName: values.ogSiteName || null,
  };
};

export const validationSchema = Yup.object()
  .shape({
    address: locationSchema,
    averagePrice: Yup.mixed(),
    contacts: contactsSchema,
    content: contentSchema,
    features: Yup.array().max(10, 'Выберите не более ${max} особенностей'),
    foods: Yup.array().max(10, 'Выберите не более ${max} кухонь'),
    gallery: Yup.array().of(imageSchema),
    image: imageSchema,
    intro: Yup.string()
      .notRequired()
      .trim()
      .max(700, 'Введите не более 600 символов'),
    name: Yup.string()
      .trim()
      .min(1, 'Введите от 1 до 255 символов')
      .max(255, 'Введите от 1 до 255 символов')
      .required(),
    restaurantTypes: Yup.array()
      .min(1, 'Выберите хотя бы ${min} тип')
      .max(3, 'Выберите не более ${max} типов'),
    shortDescription: Yup.string()
      .trim()
      .min(1, 'Введите от 1 до 300 символов')
      .max(300, 'Введите от 1 до 300 символов')
      .required(),
    status: Yup.mixed().required(),
    workTime: Yup.object().shape({
      mon: scheduleItemSchema,
      tue: scheduleItemSchema,
      wed: scheduleItemSchema,
      thu: scheduleItemSchema,
      fri: scheduleItemSchema,
      sat: scheduleItemSchema,
      sun: scheduleItemSchema,
      comment: Yup.string().max(255, 'Введите от 1 до 255 символов'),
    }),
    ogTitle: Yup.string()
      .trim()
      .min(1, 'Введите от 1 до 300 символов')
      .max(300, 'Введите от 1 до 300 символов')
      .required(),
    ogDescription: Yup.string()
      .trim()
      .min(1, 'Введите от 1 до 300 символов')
      .max(300, 'Введите от 1 до 300 символов')
      .required(),
    metaTitle: Yup.string()
      .trim()
      .min(1, 'Введите от 1 до 300 символов')
      .max(300, 'Введите от 1 до 300 символов')
      .required(),
    metaDescription: Yup.string()
      .trim()
      .min(1, 'Введите от 1 до 300 символов')
      .max(300, 'Введите от 1 до 300 символов')
      .required(),
    ogUrl: Yup.string()
      .trim()
      .min(1, 'Введите от 1 до 300 символов')
      .max(300, 'Введите от 1 до 300 символов')
      .required(),
    ogSiteName: Yup.string()
      .trim()
      .min(1, 'Введите от 1 до 300 символов')
      .max(300, 'Введите от 1 до 300 символов')
      .required(),
  })
  .concat(seoValidationSchema);

export const mapValues = (values): IRestaurantsItem => {
  return {
    address: mapAddress(values.address),
    averagePrice:
      values.averagePrice && values.averagePrice !== 0
        ? values.averagePrice
        : null,
    contacts: mapContacts(values.contacts),
    content: mapContent(values.content),
    features: mapSelect(values.features) as number[],
    foods: mapSelect(values.foods) as number[],
    gallery: mapGallery(values.gallery),
    heroImage: null,
    image: mapImage(values.image),
    intro: values?.intro?.trim() || '',
    lang: mapSelect(values.lang) as LangEnumKeys,
    metaDescription: values.metaDescription || null,
    metaKeywords: values.metaKeywords || null,
    metaTitle: values.metaTitle || null,
    name: values.name.trim(),
    ogDescription: values.ogDescription || null,
    ogTitle: values.ogTitle || null,
    restaurantTypes: mapSelect(values.restaurantTypes) as number[],
    shortDescription: values.shortDescription.trim(),
    sortPriority: values.sortPriority || null,
    sortPriorityMainPage: values.sortPriorityMainPage || null,
    status: mapSelect(values.status) as Statuses,
    workTime: values.workTime,
    ogImage: values.ogImage ? mapImageForSeo(mapImage(values.ogImage)) : null,
    ogType: values.ogType || null,
    ogUrl: values.ogUrl || null,
    ogSiteName: values.ogSiteName || null,
  };
};

export const mapValuesToDraft = (values): IRestaurantsDraftItem => {
  return {
    address:
      values.address?.city || values.address?.settlement
        ? mapAddress(values.address)
        : null,
    averagePrice:
      values.averagePrice && values.averagePrice !== 0
        ? values.averagePrice
        : null,
    contacts: values.contacts?.length > 0 ? values.contacts : [],
    content: values.content?.length ? mapContent(values.content) : [],
    foods: values.foods?.length ? (mapSelect(values.foods) as number[]) : [],
    features: values.features?.length
      ? (mapSelect(values.features) as number[])
      : [],
    gallery: values.gallery?.length ? mapGallery(values.gallery) : [],
    image: values.image ? mapImage(values.image) : null,
    intro: values?.intro?.trim() || '',
    lang: mapSelect(values.lang) as LangEnumKeys,
    metaDescription: values.metaDescription || null,
    metaKeywords: values.metaKeywords || null,
    metaTitle: values.metaTitle || null,
    name: values.name?.trim() || null,
    ogDescription: values.ogDescription || null,
    ogTitle: values.ogTitle || null,
    heroImage: null,
    restaurantTypes: values.restaurantTypes?.length
      ? (mapSelect(values.restaurantTypes) as number[])
      : [],
    shortDescription: values.shortDescription?.trim() || null,
    workTime: values.workTime,
    ogImage: values.ogImage ? mapImageForSeo(mapImage(values.ogImage)) : null,
    ogType: values.ogType || null,
    ogUrl: values.ogUrl || null,
    ogSiteName: values.ogSiteName || null,
  };
};
