// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media (max-width: 499px) {\n  .Widget-module__content--c3Ny5 {\n    padding: 0;\n  }\n}\n.Widget-module__addBtnWrapper--cSWKC {\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n.Widget-module__addBtn--Y7LBt {\n  margin: 24px auto;\n}\n.Widget-module__widgetItem--GPhOg {\n  position: relative;\n  width: 100%;\n  margin-top: 26px;\n}\n.Widget-module__widgetItem--GPhOg:first-of-type {\n  margin-top: 10px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/form/Content/Widget/Widget.module.less"],"names":[],"mappings":"AACE;EAAA;IACE,UAAA;EACF;AACF;AAEA;EACE,WAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AAAF;AAGA;EACE,iBAAA;AADF;AAIA;EACE,kBAAA;EACA,WAAA;EACA,gBAAA;AAFF;AAIE;EACE,gBAAA;AAFJ","sourcesContent":[".content {\n  @media (max-width: 499px) {\n    padding: 0;\n  }\n}\n\n.addBtnWrapper {\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.addBtn {\n  margin: 24px auto;\n}\n\n.widgetItem {\n  position: relative;\n  width: 100%;\n  margin-top: 26px;\n\n  &:first-of-type {\n    margin-top: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "Widget-module__content--c3Ny5",
	"addBtnWrapper": "Widget-module__addBtnWrapper--cSWKC",
	"addBtn": "Widget-module__addBtn--Y7LBt",
	"widgetItem": "Widget-module__widgetItem--GPhOg"
};
export default ___CSS_LOADER_EXPORT___;
