import React from 'react';
import { Helmet } from 'react-helmet';
import { Button, Col, Collapse, Row, Card } from 'antd';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { CatalogAsyncSelect } from 'components/form/selects/CatalogAsyncSelect';
import { Contacts } from 'components/form/Contacts';
import { ContentField } from 'components/form/Content/ContentField';
import { FormActions } from 'components/Layout/FormActions/FormActions';
import { InputField } from 'components/form/base/InputField';
import { LangSelectSection } from 'components/form/LangSelectSection/LangSelectSection';
import { LocationSelector } from 'components/form/AddressSelector/LocationSelector';
import { MainImage } from 'components/form/MainImage/MainImage';
import { Schedule } from 'components/form/Schedule/Schedule';
import { Status } from 'components/form/Status/Status';
import { SEO } from 'components/form/SEO/SEO';
import { TextAreaField } from 'components/form/base/TextAreaField';
import { TContentBlock } from 'components/form/Content/contentTypes';
import {
  IRestaurantsDraftItem,
  IRestaurantsItem,
} from 'store/slices/restaurants/interfaces';
import { useDeepEffect } from 'utils/useDeepEffect';
import { isEmpty, isString } from 'utils/helpers';
import { IAttractionAddress } from 'types/address';
import { TContact } from 'types/contact';
import { TImage } from 'types/image';
import { TSchedule } from 'types/schedule';
import { TSEO } from 'types/seo';
import { Statuses } from 'constants/status';
import { PREVIEW_ASPECT_RATIO } from 'constants/image';
import { LangEnumKeys } from 'constants/lang';

import { mapValues, mapValuesToDraft, validationSchema } from './formUtils';

interface IFormValues extends TSEO {
  address: IAttractionAddress;
  averagePrice: number;
  contacts: TContact[];
  content: TContentBlock[];
  gallery: TImage[];
  features: number[] | { value: number; label: string }[];
  foods: number[] | { value: number; label: string }[];
  heroImage: TImage;
  intro?: string;
  image: TImage;
  lang: LangEnumKeys | { value: LangEnumKeys; label: string };
  name: string;
  restaurantTypes: number[] | { value: number; label: string }[];
  shortDescription: string;
  status: Statuses;
  workTime: TSchedule;
}

type TProps = {
  initialValues: IFormValues;
  onFinish: (values: IRestaurantsItem) => void;
  onSaveDraft: (values: IRestaurantsDraftItem) => void;
  cancelButtonHandler: () => void;
  isDraft: boolean;
  isLoading: boolean;
};

export const RestaurantsForm: React.FC<TProps> = ({
  initialValues,
  onFinish,
  onSaveDraft,
  cancelButtonHandler,
  isDraft,
  isLoading,
}) => {
  const [activeCollpaseTabs, setActiveCollpaseTabs] = React.useState([
    'content',
    'averagePrice',
    'gallery',
    'address',
    'workTime',
    'contacts',
    'seo',
    'status',
  ]);
  const resolver = yupResolver(validationSchema);

  const methods = useForm({
    defaultValues: initialValues,
    resolver,
  });

  const handleSaveAsDraft = () => {
    const formValues = mapValuesToDraft(methods.getValues());
    onSaveDraft(formValues);
  };

  const submit = values => {
    const preparedValues = mapValues(values);

    preparedValues.ogTitle = values.ogTitle;
    preparedValues.ogDescription = values.ogDescription;
    preparedValues.ogType = values.type;
    preparedValues.ogUrl = values.ogUrl;
    preparedValues.ogSiteName = values.ogSiteName;

    onFinish(preparedValues);
  };

  useDeepEffect(() => {
    methods.reset(initialValues);
  }, [initialValues]);

  useDeepEffect(() => {
    if (!isEmpty(methods.formState.errors)) {
      setActiveCollpaseTabs([
        ...Array.from(
          new Set([
            ...activeCollpaseTabs,
            ...Object.keys(methods.formState.errors),
          ])
        ),
      ]);
    }
  }, [methods.formState.errors]);

  const lang = methods.watch('lang');

  useDeepEffect(() => {
    if (methods.formState.isDirty) {
      methods.reset({
        ...methods.getValues(),
        restaurantTypes: [],
        foods: [],
        features: [],
      });
    }
  }, [lang]);

  const langValue = isString(lang) ? lang : lang.value;

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(submit)}
        className='ant-form ant-form-vertical indent-top'
      >
        <Helmet>
          <title>{methods.watch('metaTitle') || 'Default Title'}</title>
          <meta
            name='description'
            content={methods.watch('metaDescription') || 'Default description'}
          />
          <meta name='keywords' content={methods.watch('metaKeywords')} />
          <meta property='og:title' content={methods.watch('ogTitle')} />
          <meta
            property='og:description'
            content={methods.watch('ogDescription') || 'Default OG Description'}
          />
          <meta property='og:image' content={methods.watch('ogImage') || ''} />
          <meta property='og:type' content={methods.watch('ogType') || ''} />
          <meta property='og:url' content={methods.watch('ogUrl') || ''} />
          <meta
            property='og:ogSiteName'
            content={methods.watch('ogSiteName') || ''}
          />
        </Helmet>

        <LangSelectSection />
        <Card title='Общая информация' bordered={false}>
          <Row gutter={[16, 0]}>
            <Col flex={'0 0 100px'}>
              <MainImage
                name='image'
                label='Превью'
                required
                aspectRatio={PREVIEW_ASPECT_RATIO}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <InputField
                name='name'
                label='Название места'
                required
                placeholder='Введите название'
                showCount
                maxLength={255}
              />
            </Col>
          </Row>
          <Row gutter={[24, 0]}>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <CatalogAsyncSelect
                name='restaurantTypes'
                label='Тип заведения'
                placeholder='Выберите тип'
                required
                requestParams={{
                  entityType: 'restaurants',
                  fieldType: 'restaurantTypes',
                  lang: langValue,
                }}
                mode='multiple'
              />
            </Col>
          </Row>
          <Row gutter={[24, 0]}>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <CatalogAsyncSelect
                name='foods'
                label='Кухни'
                placeholder='Выберите кухни'
                requestParams={{
                  entityType: 'restaurants',
                  fieldType: 'foods',
                  lang: langValue,
                }}
                mode='multiple'
              />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <CatalogAsyncSelect
                name='features'
                label='Особенности'
                placeholder='Выберите особенности'
                requestParams={{
                  entityType: 'restaurants',
                  fieldType: 'features',
                  lang: langValue,
                }}
                mode='multiple'
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <InputField
                name='shortDescription'
                label='Краткое описание'
                required
                placeholder='Описание'
                showCount
                maxLength={300}
              />
            </Col>
          </Row>
        </Card>
        <Collapse
          bordered={false}
          expandIconPosition='end'
          onChange={(value: string[]) => setActiveCollpaseTabs(value)}
          activeKey={activeCollpaseTabs}
        >
          <Collapse.Panel header='Описание места' key='content'>
            <Row>
              <Col span={24}>
                <TextAreaField
                  label='Введение'
                  name='intro'
                  placeholder='Текст'
                  showCount
                  maxLength={700}
                  rows={10}
                />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <ContentField
                  name='content'
                  label='Описание'
                  placeholder='Начните печатать текст'
                  withWidgetsDescription
                  withPreparingForModeration
                  required
                />
              </Col>
            </Row>
          </Collapse.Panel>
          <Collapse.Panel header='Информация по заведению' key='averagePrice'>
            <Row gutter={[24, 0]}>
              <Col xs={{ span: 24 }} md={{ span: 10 }}>
                <InputField
                  name='averagePrice'
                  label='Средний чек'
                  type='price'
                  placeholder='Укажите число'
                  maxLength={6}
                />
              </Col>
            </Row>
          </Collapse.Panel>
          <Collapse.Panel header='Адрес' key='address'>
            <LocationSelector name='address' />
          </Collapse.Panel>
          <Collapse.Panel header='Режим работы' key='workTime'>
            <Schedule name='workTime' withoutComment />
          </Collapse.Panel>
          <Collapse.Panel header='Контакты' key='contacts'>
            <Contacts name='contacts' />
          </Collapse.Panel>
          <Collapse.Panel header='SEO' key='seo'>
            <SEO />
          </Collapse.Panel>
          <Collapse.Panel header='Публикация' key='status'>
            <Status status={initialValues.status} isStatusesLimited />
          </Collapse.Panel>
        </Collapse>
        <FormActions>
          <Button onClick={cancelButtonHandler} disabled={isLoading}>
            Отмена
          </Button>
          {isDraft && (
            <Button onClick={handleSaveAsDraft} loading={isLoading}>
              Сохранить как черновик
            </Button>
          )}
          <Button type='primary' htmlType='submit' loading={isLoading}>
            Сохранить
          </Button>
        </FormActions>
      </form>
    </FormProvider>
  );
};
