import * as Yup from 'yup';
import { FieldValues } from 'react-hook-form';

import {
  mapContent,
  mapContentToForm,
  mapImage,
  mapRegion,
  mapSelect,
  mapValuesToRegion,
  mapValuesToSelect,
} from 'utils/mappings';
import { contentSchema, imageSchema, seoValidationSchema } from 'utils/yup';
import { prepareContent } from 'components/form/Content/utils';
import { StatusesEnum } from 'constants/status';
import { LangEnum, LangEnumKeys } from 'constants/lang';
import { newsTopicNamesHash, TNewsTopic } from 'constants/newsTopic';

export const mapTopicToForm = topic => ({
  value: topic,
  label: newsTopicNamesHash[topic],
});

export const mapValuesToForm = values => {
  return {
    ...values,
    content: prepareContent(mapContentToForm(values.content || [])),
    intro: values?.intro || '',
    lang: values.lang || LangEnum.ru,
    metaDescription: values.metaDescription || '',
    metaKeywords: values.metaKeywords || '',
    metaTitle: values.metaTitle || '',
    name: values.name || '',
    ogDescription: values.ogDescription || '',
    ogTitle: values.ogTitle || '',
    region: values.region ? mapValuesToRegion(values.region) : null,
    shortDescription: values.shortDescription || '',
    sourceLink: values.sourceLink || '',
    status:
      values.status === StatusesEnum.DRAFT
        ? StatusesEnum.PUBLISHED
        : values.status,
    topic: values.topic ? mapTopicToForm(values.topic) : null,
    tags: values.tagsData ? mapValuesToSelect(values.tagsData) : [],
  };
};

export const validationSchema = Yup.object()
  .shape({
    content: contentSchema,
    image: imageSchema,
    intro: Yup.string()
      .notRequired()
      .trim()
      .max(700, 'Введите не более 600 символов'),
    name: Yup.string()
      .trim()
      .min(1, 'Введите от 1 до 255 символов')
      .max(255, 'Введите от 1 до 255 символов')
      .required('Это поле необходимо заполнить'),
    region: Yup.mixed().notRequired(),
    shortDescription: Yup.string()
      .trim()
      .min(1, 'Введите от 1 до 300 символов')
      .max(300, 'Введите от 1 до 300 символов')
      .required('Это поле необходимо заполнить'),
    source: Yup.mixed(),
    sourceLink: Yup.string().url().notRequired(),
    status: Yup.mixed().required(),
    tags: Yup.array().max(10, 'Выберите не более ${max} тегов'),
    topic: Yup.mixed().required('Выберите тему'),
  })
  .concat(seoValidationSchema);

export const mapValues = (values: FieldValues) => {
  return {
    content: mapContent(values.content),
    image: mapImage(values.image),
    intro: values?.intro?.trim() || '',
    lang: mapSelect(values.lang) as LangEnumKeys,
    metaDescription: values.metaDescription || null,
    metaKeywords: values.metaKeywords || null,
    metaTitle: values.metaTitle || null,
    name: values.name.trim(),
    ogDescription: values.ogDescription || null,
    ogTitle: values.ogTitle || null,
    region: mapRegion(values.region),
    shortDescription: values.shortDescription.trim(),
    source: values.source || '',
    sourceLink: values.sourceLink || null,
    status: mapSelect(values.status),
    tags: mapSelect(values.tags),
    topic: mapSelect(values.topic) as TNewsTopic,
  };
};

export const mapValuesToDraft = values => {
  return {
    content: values.content?.length ? mapContent(values.content) : [],
    image: values.image ? mapImage(values.image) : null,
    intro: values?.intro?.trim() || '',
    lang: mapSelect(values.lang) as LangEnumKeys,
    metaKeywords: values.metaKeywords || null,
    metaDescription: values.metaDescription || null,
    metaTitle: values.metaTitle || null,
    name: values.name?.trim() || null,
    ogDescription: values.ogDescription || null,
    ogTitle: values.ogTitle || null,
    region: values.region ? mapRegion(values.region) : null,
    shortDescription: values.shortDescription?.trim() || null,
    source: values.source ? values.source : '',
    sourceLink: values.sourceLink ? values.sourceLink : null,
    tags: values.tags?.length ? mapSelect(values.tags) : [],
    topic: values.topic ? mapSelect(values.topic) : null,
  };
};
