import React from 'react';
import { Button, Card } from 'antd';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { Content } from 'antd/lib/layout/layout';

import { FloatButtons } from 'components/form/FloatButtons/FloatButtons';

import { WidgetPreview } from './WidgetPreview';
import { Entity } from './Entity';
import { widgetTypeNamesHash } from '../contentTypes';
import styles from './Widget.module.less';

type TProps = {
  widget: any;
  withWidgetsDescription: any;
  name: string;
  errors: any;
  errorRef: any;
  onChange: (value: any) => void;
};

export class WidgetContent extends React.Component<TProps> {
  static defaultProps = {
    widget: {},
    onChange: () => undefined,
  };

  handleAddWidgetItem = () => {
    const { type } = this.props.widget;
    const data = {
      ...this.props.widget,
      [type]: [...this.props.widget[type], null],
    };

    this.props.onChange(data);
  };

  handleDeleteWidgetItem = (index: number) => {
    const { type } = this.props.widget;
    const data = {
      ...this.props.widget,
      [type]: this.props.widget[type].filter(
        (_, itemIndex) => index !== itemIndex
      ),
    };

    this.props.onChange(data);
  };

  render() {
    const { widget } = this.props;
    const entities = widget[widget.type] || [];
    const lastEntityIndex = entities.length - 1;

    return (
      <Content
        className={styles.content}
        style={{ paddingTop: 0, paddingBottom: 0 }}
      >
        <div ref={this.props.errorRef} tabIndex={0}>
          <Card
            title={widgetTypeNamesHash[widget.type]}
            extra={<FloatButtons name={`${this.props.name}.float`} />}
            type='inner'
          >
            {entities.length ? (
              <>
                {entities.map((item, index) => {
                  return (
                    <Card.Grid
                      className={styles.widgetItem}
                      hoverable={false}
                      key={`${widget.type}-${item?.key || ''}-${index}`}
                    >
                      <Entity index={index} {...this.props} />
                      <div className='widget-form_preview widget-form_item'>
                        <WidgetPreview widget={item} type={widget.type} />
                      </div>
                      {entities.length > 1 && (
                        <Button
                          onClick={() => this.handleDeleteWidgetItem(index)}
                          shape='circle'
                          icon={<CloseOutlined />}
                          style={{
                            position: 'absolute',
                            top: '-17px',
                            right: '-17px',
                          }}
                        />
                      )}
                    </Card.Grid>
                  );
                })}

                {entities.length < 3 && (
                  <div className={styles.addBtnWrapper}>
                    <Button
                      className={styles.addBtn}
                      htmlType='button'
                      onClick={this.handleAddWidgetItem}
                      type='primary'
                      disabled={entities[lastEntityIndex] ? false : true}
                    >
                      <PlusOutlined />
                      Добавить
                    </Button>
                  </div>
                )}
              </>
            ) : (
              <Entity index={0} {...this.props} />
            )}
          </Card>
        </div>
      </Content>
    );
  }
}
