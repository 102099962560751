// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CreatePreviewBlock-module__createPreviewBlockContainer--pMWca {\n  display: grid;\n  grid-template-columns: 1fr;\n}\n.CreatePreviewBlock-module__createPreviewBlockContainer--pMWca .CreatePreviewBlock-module__createFormContainer--ww6xT .CreatePreviewBlock-module__createPreviewBlockForm--XdU0M {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/MainB2C/components/CreateItemModal/components/CreatePreviewBlock/CreatePreviewBlock.module.less"],"names":[],"mappings":"AACA;EACE,aAAA;EACA,0BAAA;AAAF;AAFA;EAOM,YAAA;EACA,aAAA;EACA,sBAAA;AAFN","sourcesContent":["\n.createPreviewBlockContainer {\n  display: grid;\n  grid-template-columns: 1fr;\n\n  .createFormContainer {\n\n    .createPreviewBlockForm {\n      height: 100%;\n      display: flex;\n      flex-direction: column;\n\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"createPreviewBlockContainer": "CreatePreviewBlock-module__createPreviewBlockContainer--pMWca",
	"createFormContainer": "CreatePreviewBlock-module__createFormContainer--ww6xT",
	"createPreviewBlockForm": "CreatePreviewBlock-module__createPreviewBlockForm--XdU0M"
};
export default ___CSS_LOADER_EXPORT___;
