import React from 'react';
import {
  AppstoreAddOutlined,
  BlockOutlined,
  CalendarOutlined,
  CameraOutlined,
  CodeOutlined,
  EnvironmentOutlined,
  FileUnknownOutlined,
  LineOutlined,
  MessageOutlined,
  NodeIndexOutlined,
  PictureOutlined,
  VideoCameraAddOutlined,
} from '@ant-design/icons';

import { ContentTypeEnum, WidgetTypeEnum } from '../contentTypes';
import { DropdownItemsType, TToolbarButton } from './types';

export const getToolbarButtons = (): TToolbarButton[] => {
  return [
    {
      type: ContentTypeEnum.image,
      icon: <CameraOutlined />,
      title: 'Изображение',
      initialValue: null,
    },
    {
      type: ContentTypeEnum.collage,
      icon: <BlockOutlined />,
      title: 'Коллаж',
      initialValue: {
        images: [null, null],
        text: '',
      },
    },
    {
      type: ContentTypeEnum.gallery,
      icon: <AppstoreAddOutlined />,
      title: 'Галерея',
      initialValue: [],
    },
    {
      type: ContentTypeEnum.video,
      icon: <VideoCameraAddOutlined />,
      title: 'Видео',
      initialValue: null,
    },
    {
      type: 'htmlCode',
      icon: <CodeOutlined />,
      title: 'Код',
      initialValue: null,
    },
    {
      type: ContentTypeEnum.widget,
      icon: <EnvironmentOutlined />,
      title: 'Место',
      initialValue: { type: 'place' },
    },
    {
      type: ContentTypeEnum.widget,
      icon: <PictureOutlined />,
      title: 'Событие',
      initialValue: { type: WidgetTypeEnum.events, events: null },
    },
    {
      type: ContentTypeEnum.widget,
      icon: <NodeIndexOutlined />,
      title: 'Маршрут',
      initialValue: { type: WidgetTypeEnum.routes, routes: null },
    },
    {
      type: ContentTypeEnum.widget,
      icon: <CalendarOutlined />,
      title: 'Публикации',
      initialValue: { type: 'article', article: [] },
    },
    {
      type: ContentTypeEnum.quote,
      icon: <MessageOutlined />,
      title: 'Цитата',
      initialValue: {
        author: '',
        activity: '',
        image: null,
        quote: '',
      },
    },
    {
      type: ContentTypeEnum.reference,
      icon: <FileUnknownOutlined />,
      title: 'Справка',
      initialValue: {
        title: '',
        subtitle: '',
      },
    },
    {
      type: ContentTypeEnum.separator,
      icon: <LineOutlined />,
      title: 'Разделитель',
      initialValue: 'mountain',
    },
    {
      type: ContentTypeEnum.geoPosition,
      icon: <div>Геометка</div>,
      title: 'Геометка',
      initialValue: { address: {} },
    },
  ];
};

export const getDropdownArticleItems = (): DropdownItemsType[] => {
  return [
    {
      defaultValue: null,
      label: 'Статьи',
      type: WidgetTypeEnum.articles,
    },
    {
      defaultValue: null,
      label: 'Новости',
      type: WidgetTypeEnum.news,
    },
  ];
};

export const getDropdownPlaceItems = (): DropdownItemsType[] => {
  return [
    {
      defaultValue: null,
      label: 'Достопримечательности',
      type: WidgetTypeEnum.attractions,
    },

    {
      defaultValue: null,
      label: 'Курорты',
      type: WidgetTypeEnum.resorts,
    },
    {
      defaultValue: null,
      label: 'Отели',
      type: WidgetTypeEnum.hotels,
    },
    {
      defaultValue: null,
      label: 'Кафе и рестораны',
      type: WidgetTypeEnum.restaurants,
    },
  ];
};
