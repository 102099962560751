// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CreateNumberFact-module__createNumberFactForm--wVyx8 {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n}\n.CreateNumberFact-module__createNumberFactForm--wVyx8 .CreateNumberFact-module__inputs--mLBwA {\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  gap: 10px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/MainB2C/components/CreateItemModal/components/CreateNumberFact/CreateNumberFact.module.less"],"names":[],"mappings":"AACA;EACE,aAAA;EACA,sBAAA;EACA,WAAA;AAAF;AAHA;EAMI,aAAA;EACA,8BAAA;EACA,SAAA;AAAJ","sourcesContent":["\n.createNumberFactForm {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n\n  .inputs {\n    display: grid;\n    grid-template-columns: 1fr 1fr;\n    gap: 10px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"createNumberFactForm": "CreateNumberFact-module__createNumberFactForm--wVyx8",
	"inputs": "CreateNumberFact-module__inputs--mLBwA"
};
export default ___CSS_LOADER_EXPORT___;
