import React, { useState } from 'react';
import {
  DeleteOutlined,
  DownloadOutlined,
  PaperClipOutlined,
} from '@ant-design/icons';
import { Button, notification, Row, Upload } from 'antd';
import { UploadRequestOption as RcCustomRequestOptions } from 'rc-upload/lib/interface';
import { RcFile, UploadChangeParam, UploadFile } from 'antd/lib/upload';

import { uploadImage } from 'utils/upload';
import { IFile } from 'types/file';
import { readFileAsync, readImgAsync } from 'utils/files';
import { UPLOAD_SERVICE_BASE_URL } from 'constants/image';

import {
  DEFAULT_ACCEPT,
  MAX_FILE_SIZE,
  SERVER_ERROR_DICTIONARY,
  VALID_FORMATS_LIST,
} from './constants';

type TFileUpload = RcFile & { path: string; baseUrl: string };

interface IProps {
  onChange: (value: IFile) => void;
  value: IFile;
  name: string;
  validFormatsList?: string[];
  accept?: string;
  uploadsUnsupportedFileType?: string;
  buttonTitle?: string;
  maxFileSize?: number;
  isIgnoreResolution?: boolean;
  validHeight: number;
  validWith: number;
  textInfo?: boolean;
}

export const BaseUploadSingleFile = ({
  onChange,
  value,
  name,
  validFormatsList = VALID_FORMATS_LIST,
  accept = DEFAULT_ACCEPT,
  uploadsUnsupportedFileType = SERVER_ERROR_DICTIONARY.UPLOADS_UNSUPPORTED_FILE_TYPE,
  buttonTitle = 'Загрузить иконку',
  maxFileSize = MAX_FILE_SIZE,
  isIgnoreResolution = false,
  validHeight,
  validWith,
  textInfo = false,
}: IProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleFileChange = (info: UploadChangeParam<UploadFile<RcFile>>) => {
    setIsLoading(true);
    uploadImage({ ...info.file, file: info.file.originFileObj })
      .then((res: TFileUpload) => {
        const file = {
          baseUrl: UPLOAD_SERVICE_BASE_URL,
          path: res.path,
          realName: res.name,
        };
        onChange(file);
      })
      .catch(error =>
        notification.error({
          message: error.message,
        })
      )
      .finally(() => setIsLoading(false));
  };

  const handleBeforeUpload = (file: RcFile) => {
    const isValidType = validFormatsList.includes(file.type);
    if (!isValidType) {
      notification.error({
        message: uploadsUnsupportedFileType,
      });
    }
    const isValidSize = file.size < maxFileSize;
    if (!isValidSize) {
      notification.error({
        message: 'Пожалуйста, выберите файл с размером не более 100 КБ',
      });
    }

    if (!isValidType || !isValidSize) return Upload.LIST_IGNORE;

    return readFileAsync(file).then(file => {
      return readImgAsync(file).then(img => {
        const { height, width } = img as HTMLImageElement;
        const isValidScreen = width === validWith && height === validHeight;
        if (!isValidScreen && isIgnoreResolution === false) {
          notification.error({
            message: `Пожалуйста, выберите файл с разрешением ${validWith}*${validHeight}`,
          });

          return isValidScreen || Upload.LIST_IGNORE;
        }
      });
    });
  };

  const dummyRequest = ({ onSuccess }: RcCustomRequestOptions) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };
  return (
    <Row align='middle' wrap={false}>
      {!value ? (
        <Upload
          accept={accept}
          name={name}
          beforeUpload={handleBeforeUpload}
          customRequest={dummyRequest}
          onChange={handleFileChange}
          showUploadList={false}
          fileList={[]}
        >
          <Button
            icon={<DownloadOutlined />}
            disabled={isLoading}
            loading={isLoading}
          >
            {buttonTitle}
          </Button>
        </Upload>
      ) : (
        <Row align='middle' style={{ width: '100%' }} wrap={false}>
          {textInfo ? <div>Картинка загружена</div> : null}
          <PaperClipOutlined
            style={{
              color: '#8C8C8C',
            }}
          />
          <a
            style={{
              minWidth: 0,
              marginLeft: '8px',
              marginRight: '8px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
            target='_blank'
            href={`${UPLOAD_SERVICE_BASE_URL}/${value.path}`}
            rel='noreferrer'
          >
            {value.realName}
          </a>
          <Button
            type='text'
            icon={<DeleteOutlined />}
            onClick={() => onChange(null)}
            style={{
              marginLeft: 'auto',
              color: '#8C8C8C',
            }}
          />
        </Row>
      )}
    </Row>
  );
};
