import React from 'react';
import { Helmet } from 'react-helmet';
import { Button, Col, Collapse, Row, Radio, Form, Tooltip, Card } from 'antd';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { GlobalOutlined, QuestionCircleOutlined } from '@ant-design/icons';

import { CatalogAsyncSelect } from 'components/form/selects/CatalogAsyncSelect';
import { Contacts } from 'components/form/Contacts';
import { ContentField } from 'components/form/Content/ContentField';
import { FormActions } from 'components/Layout/FormActions/FormActions';
import { HotelComfortGroups } from 'components/form/Hotel/HotelComfortGroups';
import { HotelTerms } from 'components/form/Hotel/HotelTerms';
import { InputField } from 'components/form/base/InputField';
import { InputRating } from 'components/form/base/InputRating';
import { LangSelectSection } from 'components/form/LangSelectSection/LangSelectSection';
import { LocationSelector } from 'components/form/AddressSelector/LocationSelector';
import { MainImage } from 'components/form/MainImage/MainImage';
import { SEO } from 'components/form/SEO/SEO';
import { Status } from 'components/form/Status/Status';
import { TextAreaField } from 'components/form/base/TextAreaField';
import { TContentBlock } from 'components/form/Content/contentTypes';
import {
  IHotelsDraftItem,
  IHotelsItem,
  TComfortGroup,
} from 'store/slices/hotels/interfaces';
import { useDeepEffect } from 'utils/useDeepEffect';
import { isEmpty, isString } from 'utils/helpers';
import { IAttractionAddress } from 'types/address';
import { TContact } from 'types/contact';
import { TImage } from 'types/image';
import { TSEO } from 'types/seo';
import { PREVIEW_ASPECT_RATIO } from 'constants/image';
import { LangEnumKeys } from 'constants/lang';
import { Statuses } from 'constants/status';

import { mapValues, mapValuesToDraft, validationSchema } from '../formUtils';
import styles from './HotelsForm.module.less';

interface IFormValues extends TSEO {
  address: IAttractionAddress;
  arrivalTime: string;
  category: number | { value: number; label: string };
  comfortGroups: TComfortGroup[];
  content: TContentBlock[];
  contacts: TContact[];
  departureTime: string;
  externalUrl: string;
  features: number[] | { value: number; label: string }[];
  gallery: TImage[];
  heroImage: TImage;
  hotelComforts: number[] | { value: number; label: string }[];
  image: TImage;
  intro?: string;
  lang: LangEnumKeys | { value: LangEnumKeys; label: string };
  name: string;
  price: number;
  rating: number;
  shortDescription: string;
  starRating: typeof hotelStars[number];
  status: Statuses;
}

type TProps = {
  initialValues: IFormValues;
  onFinish: (values: IHotelsItem) => void;
  onSaveDraft: (values: IHotelsDraftItem) => void;
  cancelButtonHandler: () => void;
  isDraft: boolean;
  isLoading: boolean;
};

export const hotelStars = ['Не задано', 1, 2, 3, 4, 5] as const;

export const HotelsForm: React.FC<TProps> = ({
  initialValues,
  onFinish,
  onSaveDraft,
  cancelButtonHandler,
  isDraft,
  isLoading,
}) => {
  const [activeCollpaseTabs, setActiveCollpaseTabs] = React.useState([
    'content',
    'gallery',
    'address',
    'terms',
    'hotelComfortGroups',
    'contacts',
    'externalUrl',
    'seo',
    'status',
  ]);
  const resolver = yupResolver(validationSchema);

  const methods = useForm({
    defaultValues: initialValues,
    resolver,
  });

  const handleSaveAsDraft = () => {
    const formValues = mapValuesToDraft(methods.getValues());
    onSaveDraft(formValues);
  };

  const submit = values => {
    const preparedValues = mapValues(values);

    preparedValues.ogTitle = values.ogTitle;
    preparedValues.ogDescription = values.ogDescription;
    preparedValues.ogType = values.ogType;
    preparedValues.ogUrl = values.ogUrl;
    preparedValues.ogSiteName = values.ogSiteName;

    onFinish(preparedValues);
  };

  useDeepEffect(() => {
    methods.reset(initialValues);
  }, [initialValues]);

  useDeepEffect(() => {
    if (!isEmpty(methods.formState.errors)) {
      setActiveCollpaseTabs([
        ...Array.from(
          new Set([
            ...activeCollpaseTabs,
            ...Object.keys(methods.formState.errors),
          ])
        ),
      ]);
    }
  }, [methods.formState.errors]);

  const lang = methods.watch('lang');

  useDeepEffect(() => {
    if (methods.formState.isDirty) {
      methods.reset({
        ...methods.getValues(),
        category: null,
        hotelComforts: [],
        features: [],
        comfortGroups: [],
      });
    }
  }, [lang]);

  const langValue = isString(lang) ? lang : lang.value;

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(submit)}
        className='ant-form ant-form-vertical indent-top'
      >
        <Helmet>
          <title>{methods.watch('metaTitle') || 'Default Title'}</title>
          <meta
            name='description'
            content={methods.watch('metaDescription') || 'Default description'}
          />
          <meta name='keywords' content={methods.watch('metaKeywords')} />
          <meta property='og:title' content={methods.watch('ogTitle')} />
          <meta
            property='og:description'
            content={methods.watch('ogDescription') || 'Default OG Description'}
          />
          <meta property='og:image' content={methods.watch('ogImage') || ''} />
          <meta property='og:type' content={methods.watch('ogType') || ''} />
          <meta property='og:url' content={methods.watch('ogUrl') || ''} />
          <meta
            property='og:ogSiteName'
            content={methods.watch('ogSiteName') || ''}
          />
        </Helmet>

        <LangSelectSection />
        <Card title='Общая информация' bordered={false}>
          <Row gutter={[16, 0]}>
            <Col flex={'0 0 100px'}>
              <MainImage
                name='image'
                label='Превью'
                required
                aspectRatio={PREVIEW_ASPECT_RATIO}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <InputField
                name='name'
                label='Название места'
                required
                placeholder='Введите название'
                showCount
                maxLength={255}
              />
            </Col>
          </Row>
          <Row gutter={[24, 0]}>
            <Col xs={{ span: 24 }} md={{ span: 8 }}>
              <CatalogAsyncSelect
                name='category'
                label='Категория'
                placeholder='Выберите категорию'
                required
                requestParams={{
                  entityType: 'hotels',
                  fieldType: 'categories',
                  lang: langValue,
                }}
              />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 16 }}>
              <CatalogAsyncSelect
                name='hotelComforts'
                label='Главные удобства в отеле'
                placeholder='Выберите удобства'
                requestParams={{
                  entityType: 'hotels',
                  fieldType: 'hotelComforts',
                  lang: langValue,
                }}
                mode='multiple'
              />
            </Col>
          </Row>
          <Row gutter={[24, 0]}>
            <Col>
              <Form.Item label='Звездность отеля'>
                <Radio.Group
                  defaultValue={initialValues.starRating}
                  onChange={e => methods.setValue('starRating', e.target.value)}
                >
                  {hotelStars.map(item => (
                    <Radio.Button
                      key={item}
                      value={item}
                      checked={item === initialValues.starRating}
                      className={styles.starRatingButton}
                    >
                      {item}
                    </Radio.Button>
                  ))}
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 6 }}>
              <InputRating
                name='rating'
                label='Рейтинг отеля'
                placeholder='Введите число'
                step={0.1}
                min={0.5}
                max={10}
                allowClear
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <InputField
                name='shortDescription'
                label='Краткое описание'
                required
                placeholder='Введите описание'
                showCount
                maxLength={300}
              />
            </Col>
          </Row>
        </Card>
        <Collapse
          bordered={false}
          expandIconPosition='end'
          onChange={(value: string[]) => setActiveCollpaseTabs(value)}
          activeKey={activeCollpaseTabs}
        >
          <Collapse.Panel header='Описание места' key='content'>
            <Row>
              <Col span={24}>
                <TextAreaField
                  label='Введение'
                  name='intro'
                  placeholder='Текст'
                  showCount
                  maxLength={700}
                  rows={10}
                />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <ContentField
                  name='content'
                  label='Описание'
                  placeholder='Начните печатать текст'
                  withWidgetsDescription
                  withPreparingForModeration
                  required
                />
              </Col>
            </Row>
          </Collapse.Panel>
          <Collapse.Panel header='Адрес' key='address'>
            <LocationSelector name='address' />
          </Collapse.Panel>
          <Collapse.Panel header='Условия' key='terms'>
            <HotelTerms lang={langValue} />
          </Collapse.Panel>
          <Collapse.Panel
            header={
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'nowrap',
                  alignItems: 'center',
                }}
              >
                <div style={{ marginRight: 10 }}>Группы удобств</div>
                <Tooltip
                  placement='top'
                  title={`Количество элементов должно быть не более 10. Перечень удобств должен быть разделен между собой знаком препинания ";"
                    (Например: Кондиционер; Стиральная машина; Балкон; и т.д.)`}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            }
            key='hotelComfortGroups'
          >
            <HotelComfortGroups name='comfortGroups' lang={langValue} />
          </Collapse.Panel>
          <Collapse.Panel header='Контакты' key='contacts'>
            <Contacts name='contacts' />
          </Collapse.Panel>
          <Collapse.Panel header='Продажа на внешнем сайте' key='externalUrl'>
            <InputField
              name='externalUrl'
              label='Ссылка на продажу на стороннем сайте'
              placeholder='Ссылка на продажу на стороннем сайте'
              addonBefore={<GlobalOutlined />}
              formItemStyle={{ marginBottom: 0 }}
            />
          </Collapse.Panel>
          <Collapse.Panel header='SEO' key='seo'>
            <SEO />
          </Collapse.Panel>
          <Collapse.Panel header='Публикация' key='status'>
            <Status status={initialValues.status} isStatusesLimited />
          </Collapse.Panel>
        </Collapse>
        <FormActions>
          <Button onClick={cancelButtonHandler} disabled={isLoading}>
            Отмена
          </Button>
          {isDraft && (
            <Button onClick={handleSaveAsDraft} loading={isLoading}>
              Сохранить как черновик
            </Button>
          )}
          <Button type='primary' htmlType='submit' loading={isLoading}>
            Сохранить
          </Button>
        </FormActions>
      </form>
    </FormProvider>
  );
};
