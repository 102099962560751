import * as Yup from 'yup';

import {
  contactsSchema,
  contentSchema,
  imageSchema,
  locationSchema,
  scheduleItemSchema,
  seoValidationSchema,
} from 'utils/yup';
import {
  mapAddress,
  mapContacts,
  mapContent,
  mapContentToForm,
  mapGallery,
  mapImage,
  mapImageForSeo,
  mapSelect,
  mapValuesToAddress,
  mapValuesToSelect,
} from 'utils/mappings';
import { prepareContent } from 'components/form/Content/utils';
import { Statuses, StatusesEnum } from 'constants/status';
import {
  IAttractionDraftItem,
  IAttractionItem,
} from 'store/slices/attractions/interfaces';
import { LangEnum, LangEnumKeys } from 'constants/lang';

export const mapValuesToForm = values => {
  return {
    ...values,
    address: values.address ? mapValuesToAddress(values.address) : {},
    category: values.categoryData
      ? mapValuesToSelect(values.categoryData)
      : null,
    content: prepareContent(mapContentToForm(values.content || [])),
    intro: values?.intro || '',
    lang: values.lang || LangEnum.ru,
    metaDescription: values.metaDescription || '',
    metaKeywords: values.metaKeywords || '',
    metaTitle: values.metaTitle || '',
    name: values.name || '',
    ogDescription: values.ogDescription || '',
    ogTitle: values.ogTitle || '',
    shortDescription: values.shortDescription || '',
    sortPriority: values.sortPriority || null,
    sortPriorityMainPage: values.sortPriority || null,
    status:
      values.status === StatusesEnum.DRAFT
        ? StatusesEnum.PUBLISHED
        : values.status,
    tags: values.tagsData ? mapValuesToSelect(values.tagsData) : [],
    ogType: values.ogType || null,
    ogUrl: values.ogUrl || null,
    ogSiteName: values.ogSiteName || null,
  };
};

export const validationSchema = Yup.object()
  .shape({
    address: locationSchema,
    category: Yup.mixed().required(),
    contacts: contactsSchema,
    content: contentSchema,
    gallery: Yup.array().of(imageSchema),
    image: imageSchema,
    intro: Yup.string()
      .notRequired()
      .trim()
      .max(700, 'Введите не более 600 символов'),
    name: Yup.string()
      .trim()
      .min(1, 'Введите от 1 до 255 символов')
      .max(255, 'Введите от 1 до 255 символов')
      .required(),
    shortDescription: Yup.string()
      .trim()
      .min(1, 'Введите от 1 до 300 символов')
      .max(300, 'Введите от 1 до 300 символов')
      .required(),
    ogTitle: Yup.string()
      .trim()
      .min(1, 'Введите от 1 до 300 символов')
      .max(300, 'Введите от 1 до 300 символов')
      .required(),
    ogDescription: Yup.string()
      .trim()
      .min(1, 'Введите от 1 до 300 символов')
      .max(300, 'Введите от 1 до 300 символов')
      .required(),
    metaTitle: Yup.string()
      .trim()
      .min(1, 'Введите от 1 до 300 символов')
      .max(300, 'Введите от 1 до 300 символов')
      .required(),
    metaDescription: Yup.string()
      .trim()
      .min(1, 'Введите от 1 до 300 символов')
      .max(300, 'Введите от 1 до 300 символов')
      .required(),
    ogUrl: Yup.string()
      .trim()
      .min(1, 'Введите от 1 до 300 символов')
      .max(300, 'Введите от 1 до 300 символов')
      .required(),
    ogSiteName: Yup.string()
      .trim()
      .min(1, 'Введите от 1 до 300 символов')
      .max(300, 'Введите от 1 до 300 символов')
      .required(),
    status: Yup.mixed().required(),
    tags: Yup.array().max(10, 'Выберите не более ${max} тегов'),
    workTime: Yup.object().shape({
      mon: scheduleItemSchema,
      tue: scheduleItemSchema,
      wed: scheduleItemSchema,
      thu: scheduleItemSchema,
      fri: scheduleItemSchema,
      sat: scheduleItemSchema,
      sun: scheduleItemSchema,
      comment: Yup.string().max(255, 'Введите от 1 до 255 символов'),
    }),
  })
  .concat(seoValidationSchema);

export const mapValues = (values): Partial<IAttractionItem> => {
  return {
    address: mapAddress(values.address),
    category: mapSelect(values.category) as number,
    contacts: mapContacts(values.contacts),
    content: mapContent(values.content),
    gallery: mapGallery(values.gallery),
    heroImage: null,
    image: mapImage(values.image),
    intro: values?.intro?.trim() || '',
    lang: mapSelect(values.lang) as LangEnumKeys,
    metaDescription: values.metaDescription || null,
    metaKeywords: values.metaKeywords || null,
    metaTitle: values.metaTitle || null,
    name: values.name.trim(),
    shortDescription: values.shortDescription.trim(),
    sortPriority: values.sortPriority || null,
    sortPriorityMainPage: values.sortPriority || null,
    status: mapSelect(values.status) as Statuses,
    tags: mapSelect(values.tags) as number[],
    workTime: values.workTime,
    ogImage: values.ogImage ? mapImageForSeo(mapImage(values.ogImage)) : null,
    ogDescription: values.ogDescription || null,
    ogTitle: values.ogTitle || null,
    ogType: values.ogType || null,
    ogUrl: values.ogUrl || null,
    ogSiteName: values.ogSiteName || null,
  };
};

export const mapValuesToDraft = (values): IAttractionDraftItem => {
  return {
    address:
      values.address?.city || values.address?.settlement
        ? mapAddress(values.address)
        : null,
    category: values.category ? (mapSelect(values.category) as number) : null,
    contacts: values.contacts?.length > 0 ? values.contacts : [],
    content: values.content?.length ? mapContent(values.content) : [],
    gallery: values.gallery?.length ? mapGallery(values.gallery) : [],
    heroImage: null,
    image: values.image ? mapImage(values.image) : null,
    intro: values?.intro?.trim() || '',
    lang: mapSelect(values.lang) as LangEnumKeys,
    metaDescription: values.metaDescription || null,
    metaKeywords: values.metaKeywords || null,
    metaTitle: values.metaTitle || null,
    name: values.name?.trim() || null,
    ogDescription: values.ogDescription || null,
    ogTitle: values.ogTitle || null,
    shortDescription: values.shortDescription?.trim() || null,
    sortPriority: values.sortPriority || null,
    sortPriorityMainPage: values.sortPriority || null,
    tags: values.tags?.length ? (mapSelect(values.tags) as number[]) : [],
    workTime: values.workTime,
    ogImage: values.ogImage ? mapImageForSeo(mapImage(values.ogImage)) : null,
    ogType: values.ogType || null,
    ogUrl: values.ogUrl || null,
    ogSiteName: values.ogSiteName || null,
  };
};
