import React from 'react';
import { Content } from 'antd/lib/layout/layout';

import { TextAreaField } from 'components/form/base/TextAreaField';

type TProps = {
  name: string;
  value: any;
  required?: boolean;
  noStyle?: boolean;
};

export const HtmlCode = React.forwardRef<HTMLDivElement>(
  (props: TProps, ref: React.ForwardedRef<HTMLDivElement>) => {
    return (
      <div className='section' ref={ref}>
        <Content style={{ backgroundColor: '#FAFAFA' }}>
          <TextAreaField
            placeholder='Добавьте HTML-код'
            label='HTML-код'
            {...props}
            autoComplete={'chrome-off'}
          />
        </Content>
      </div>
    );
  }
);

HtmlCode.displayName = 'HtmlCode';
