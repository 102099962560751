import React from 'react';
import classNames from 'classnames';
import { CloseOutlined, ThunderboltOutlined } from '@ant-design/icons';
import { Button, Form } from 'antd';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

import { prepareTextForModeration } from 'utils/content';
import { omit, pick } from 'utils/helpers';
import { useDeepEffect } from 'utils/useDeepEffect';
import { ToolbarButtons } from 'constants/content';

import { ErrorTooltip } from '../ErrorTooltip';
import { FormContentCollage } from './Collage/Collage';
import { GalleryContent } from './Gallery';
import { HtmlCode } from './HtmlCode';
import { FormContentImage } from './Image/Image';
import { Manual } from './Manual/Manual';
import { Quote } from './Quote/Quote';
import { Separator } from './Separator/Separator';
import { Reference } from './Reference/Reference';
import { TextFormContent } from './Text';
import { Toolbar } from './Toolbar/Toolbar';
import { Video } from './Video';
import { WidgetContent } from './Widget';
import { prepareContent } from './utils';
import { ContentType, ContentTypeEnum } from './contentTypes';
import { toolbarButtonsDefault } from './Wysiwyg';
import { createButton } from './Wysiwyg/helpers';

const contentItemHash = {
  collage: FormContentCollage,
  gallery: GalleryContent,
  geoPosition: Manual,
  htmlCode: HtmlCode,
  image: FormContentImage,
  quote: Quote,
  reference: Reference,
  separator: Separator,
  text: TextFormContent,
  video: Video,
  widget: WidgetContent,
};

interface IContentProps {
  name: string;
  label?: string | JSX.Element;
  enabledTypes?: ContentType[];
  withPreparingForModeration?: boolean;
  withWidgetsDescription?: boolean;
  hint?: string;
  placeholder?: string;
  required?: boolean;
  customErrorText?: string;
  isRoutesForm?: boolean;
}

interface IComponentData {
  index: number;
  line: number;
}

const toolbarRouteButtons = [
  ...toolbarButtonsDefault,
  createButton(ToolbarButtons.ANCHOR_ID),
];

export const ContentField: React.FC<IContentProps> = ({
  enabledTypes = [
    ContentTypeEnum.collage,
    ContentTypeEnum.gallery,
    ContentTypeEnum.geoPosition,
    ContentTypeEnum.htmlCode,
    ContentTypeEnum.image,
    ContentTypeEnum.quote,
    ContentTypeEnum.reference,
    ContentTypeEnum.separator,
    ContentTypeEnum.video,
    ContentTypeEnum.widget,
  ],
  withWidgetsDescription = false,
  withPreparingForModeration = false,
  label = 'Описание',
  name,
  placeholder = 'Начните печатать текст',
  required,
  customErrorText,
  isRoutesForm = false,
}) => {
  const {
    control,
    formState,
    getFieldState,
    setValue,
    trigger,
    reset,
    getValues,
  } = useFormContext();
  const fields = useWatch({ name, control });

  const [toolbarPosition, setToolbarPosition] = React.useState(0);
  const [showToolbar, setShowToolbar] = React.useState(false);
  const [isToolbarOpen, setIsToolbarOpen] = React.useState(false);
  const [isPreparedForModeration, setIsPreparedForModeration] =
    React.useState(false);
  const [activeTextComponentData, setActiveTextComponentData] =
    React.useState<IComponentData>({
      index: 0,
      line: 0,
    });
  const refContentEl = React.useRef(null);
  const componentsRefs = React.useRef([]);

  useDeepEffect(() => {
    if (isPreparedForModeration) {
      setIsPreparedForModeration(false);
    }
  }, [fields]);

  const onAddItem = (type: ContentType, initialValue: any) => {
    const index = activeTextComponentData.index;
    const line = activeTextComponentData.line;
    const component = componentsRefs.current[index];

    const content = fields.map(item => omit(item, 'id'));
    const texts = component.splitText(line);
    const contentData = {
      type,
      [type]: initialValue || null,
    };

    content[index] = {
      ...content[index],
      ...{ text: texts[0] },
    };

    content.splice(index + 1, 0, contentData, { type: 'text', text: texts[1] });
    setShowToolbar(false);
    setIsToolbarOpen(false);
    reset({
      ...getValues(),
      [name]: prepareContent(content),
    });
  };

  const onRemoveItem = index => {
    const newValue = [...fields];
    newValue.splice(index, 1);
    reset({
      ...getValues(),
      [name]: prepareContent(newValue),
    });
  };

  const onCaretNearEmptyLine = (index, position, line) => {
    const contentPosition = refContentEl.current.getBoundingClientRect();
    const toolbarPosition = position.top - contentPosition.top - 20;

    setActiveTextComponentData({ index, line });
    setToolbarPosition(toolbarPosition);
    setShowToolbar(true);
    setIsToolbarOpen(false);
  };

  const onCaretInsideText = () => {
    setShowToolbar(false);
    setIsToolbarOpen(false);
  };

  const toggleToolbar = () => {
    setIsToolbarOpen(!isToolbarOpen);
  };

  const prepareForModeration = () => {
    fields.map((item, index) => {
      if (
        item.type === 'text' &&
        prepareTextForModeration(item.text) !== item.text
      ) {
        setValue(`${name}[${index}]`, {
          type: item.type,
          text: prepareTextForModeration(item.text),
        });
      }
    });
  };

  const makePropsHash = {
    text: (index, props) => {
      props = {
        onCaretNearEmptyLine: (position, line) =>
          onCaretNearEmptyLine(index, position, line),
        onCaretInsideText,
        toolbarButtons: isRoutesForm ? toolbarRouteButtons : undefined,
        ...props,
      };

      if (!index) {
        props.placeholder = placeholder;
      }

      return props;
    },
    widget: (index, props) => ({
      ...props,
    }),
    geoPosition: (index, props) => ({
      ...props,
    }),
    image: (index, props) => props,
    collage: (index, props) => props,
    gallery: (index, props) => props,
    clickableImage: (index, props) => props,
    video: (index, props) => props,
    quote: (index, props) => props,
    separator: (index, props) => props,
    htmlCode: (index, props) => props,
    reference: (index, props) => {
      props = {
        onCaretNearEmptyLine: (position, line) => onCaretInsideText(),
        onCaretInsideText,
        ...props,
      };

      return props;
    },
  };

  const { error } = getFieldState(name, formState);
  const contentItems = fields.map((item, index) => {
    const Component = contentItemHash[item.type];
    const options = makePropsHash[item.type](index, pick(item, item.type));
    const sectionTypes = ['video', 'htmlCode'];

    return (
      <div
        key={`${item.id} + ${index}`}
        className={classNames('ant-content-block', {
          ['ant-content-block__section']: sectionTypes.includes(item.type),
        })}
      >
        {item.type !== 'text' && (
          <Button
            shape='circle'
            type='primary'
            icon={<CloseOutlined />}
            onClick={() => onRemoveItem(index)}
            className='ant-content__remove-item-button'
          />
        )}

        {[
          'text',
          'image',
          'gallery',
          'collage',
          'widget',
          'separator',
          'reference',
        ].includes(item.type) ? (
          <Controller
            name={`${name}[${index}].${item.type}`}
            render={({ field, fieldState }) => (
              <Component
                {...options}
                {...field}
                value={fields[index][item.type]}
                withWidgetsDescription={withWidgetsDescription}
                onChange={value => {
                  field.onChange(value);
                  // clear or apply error for content block
                  // only if form was validated after submiting
                  if (formState.isSubmitted && (!value || error)) {
                    trigger(name);
                  }
                }}
                error={fieldState.error}
                // eslint-disable-next-line no-return-assign
                ref={ref => (componentsRefs.current[index] = ref)}
                errorRef={field.ref}
              />
            )}
            control={control}
          />
        ) : (
          <Component
            {...options}
            // eslint-disable-next-line no-return-assign
            ref={ref => (componentsRefs.current[index] = ref)}
            // errors={errors}
            name={`${name}[${index}].${item.type}`}
            withWidgetsDescription={withWidgetsDescription}
            defaultLocation={{}}
          />
        )}
      </div>
    );
  });

  const getErrorText = error => {
    if (customErrorText) {
      return customErrorText;
    }
    if (error[0]?.text) {
      return 'Это поле необходимо заполнить';
    }
    return 'Проверьте правильность заполнения полей';
  };
  return (
    <div className='content-wrapper'>
      <Form.Item label={label} required={required}>
        <div style={{ backgroundColor: '#fff' }}>
          <div
            className='content ant-content ant-form ant-form-vertical'
            ref={refContentEl}
          >
            {withPreparingForModeration ? (
              <Button
                disabled={isPreparedForModeration}
                type='link'
                onClick={() => prepareForModeration()}
                style={{
                  position: 'absolute',
                  bottom: '4px',
                  right: '16px',
                  padding: 0,
                  zIndex: 2,
                }}
                icon={<ThunderboltOutlined />}
              >
                Подготовить к модерации
              </Button>
            ) : null}
            {Boolean(enabledTypes.length) && showToolbar ? (
              <div className='content_toolbar' style={{ top: toolbarPosition }}>
                <Toolbar
                  open={isToolbarOpen}
                  onAddItem={onAddItem}
                  onOpenClick={toggleToolbar}
                  enabledTypes={enabledTypes}
                />
              </div>
            ) : null}
            <div className='content_items'>{contentItems}</div>
            <ErrorTooltip error={(error && getErrorText(error)) || null} />
          </div>
        </div>
      </Form.Item>
    </div>
  );
};
