import React, { FC, useEffect } from 'react';
import classNames from 'classnames';
import { Button } from 'antd';
import { FormProvider, useForm } from 'react-hook-form';
import { nanoid } from 'nanoid';

import {
  MAIN_B2C_ITEM_SETTINGS,
  MAIN_B2C_PREVIEW_BLOCK_TYPE,
} from '../../../../const';
import styles from './CreatePreviewBlock.module.less';
import { IRowPreviewBlockItem } from '../../../../types/types';

interface ICreatePreviewBlockProps {
  selectedType: typeof MAIN_B2C_PREVIEW_BLOCK_TYPE[keyof typeof MAIN_B2C_PREVIEW_BLOCK_TYPE];
  handleSubmit: (values: IRowPreviewBlockItem) => void;
  setModifyingItem: (values: IRowPreviewBlockItem) => void;
  modifyingItem: IRowPreviewBlockItem;
}

export const CreatePreviewBlock: FC<ICreatePreviewBlockProps> = ({
  selectedType,
  handleSubmit,
  modifyingItem,
  setModifyingItem,
}) => {
  const methods = useForm<IRowPreviewBlockItem>({});

  const onSubmit = async () => {
    const itemId = modifyingItem?.id ?? nanoid();
    handleSubmit({
      ...MAIN_B2C_ITEM_SETTINGS[selectedType],
      id: itemId,
      key: itemId,
    });
  };

  useEffect(() => {
    return () => {
      setModifyingItem(null);
    };
  }, []);

  return (
    <div className={styles.createPreviewBlockContainer}>
      <div className={styles.createFormContainer}>
        <FormProvider {...methods}>
          <form
            className={classNames(
              'ant-form',
              'ant-form-vertical',
              styles.createPreviewBlockForm
            )}
            onSubmit={methods.handleSubmit(onSubmit)}
          >
            <Button
              type='primary'
              htmlType='submit'
              style={{ marginTop: 'auto', alignSelf: 'end' }}
              loading={false}
            >
              Создать
            </Button>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};
