import React from 'react';

export const TrianglesIcon: React.FC = () => {
  return (
    <svg
      width='50'
      height='13'
      viewBox='0 0 50 13'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g opacity='0.25'>
        <path
          d='M12.0715 11.7793H1.72853L6.9 2.78543L12.0715 11.7793Z'
          stroke='#0161FD'
          strokeWidth='2'
        />
        <path
          d='M29.8715 11.7793H19.5286L24.7 2.78543L29.8715 11.7793Z'
          stroke='#0161FD'
          strokeWidth='2'
        />
        <path
          d='M47.671 11.7718H37.3292L42.5001 2.78448L47.671 11.7718Z'
          stroke='#0161FD'
          strokeWidth='2'
        />
      </g>
    </svg>
  );
};
