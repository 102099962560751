import React, { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Input, Button, Collapse } from 'antd';
import { nanoid } from 'nanoid';

import { IRowThreeHeadersItem } from '../../../../types/types';
import {
  MAIN_B2C_ITEM_SETTINGS,
  MAIN_B2C_TABLE_NAMES,
} from '../../../../const';
import { debounce } from '../../../../../../utils/helpers';
import { getUrlWithParams, urls } from '../../../../../../store/api';
import { apiClient } from '../../../../../../utils/http';
import { CatalogList } from '../CatalogList/CatalogList';
import styles from './CreateThreeHeaders.module.less';

interface ICreateThreeHeadersProps {
  handleSubmit: (values: IRowThreeHeadersItem) => void;
  setModifyingItem: (values: IRowThreeHeadersItem) => void;
  modifyingItem: IRowThreeHeadersItem;
  selectedType: keyof typeof MAIN_B2C_ITEM_SETTINGS;
}

export const CreateThreeHeaders: FC<ICreateThreeHeadersProps> = ({
  handleSubmit,
  setModifyingItem,
  modifyingItem,
  selectedType,
}) => {
  const [catalogsDataIsLoading, setCatalogsDataIsLoading] =
    useState<boolean>(false);
  const [catalogsData, setCatalogsData] = useState([]);
  const [nameValue, setNameValue] = useState('');
  const [headers, setHeaders] = useState({
    1: null,
    2: null,
    3: null,
  });

  const getCatalogsData = debounce(async () => {
    setCatalogsDataIsLoading(true);

    try {
      const { data } = await apiClient.get(urls.api.previewB2C.materials.get);

      setCatalogsData(data);
    } catch (e) {
      console.error(e);
    } finally {
      setCatalogsDataIsLoading(false);
    }
  }, 300);

  const onSubmit = async values => {
    handleSubmit({
      ...(MAIN_B2C_ITEM_SETTINGS?.[selectedType] || {}),
      ...values,
    });
  };

  const handleNameInput = (event: ChangeEvent<HTMLInputElement>) => {
    setNameValue(event.target.value);
  };

  const onSubmitHandler = () => {
    onSubmit({
      title: nameValue,
      status: 'published',
      previews: Object.values(headers).map(el => {
        const tableName = el.tableName || el.entityName;
        const entityId = el.entityId || el.id;
        return {
          id: nanoid(),
          title: el.title || el.name,
          link: `${MAIN_B2C_TABLE_NAMES[tableName] ?? tableName}/${entityId}`,
          sortPriority: 1,
          sourceTable: el.sourceTable,
          sourceId: 1,
          lang: 'ru',
          entityName: el.entityName,
          entityId: el.entityId,
        };
      }),
    });
  };

  const handleSetHeader = (headerNumber: number, catalog: any) => {
    setHeaders({
      ...headers,
      [headerNumber]: {
        ...catalog,
        title: catalog.name,
        entityName: catalog.tableName,
        entityId: catalog.id,
      },
    });
  };

  const dataIsValid = useMemo(() => {
    if (nameValue === '') return false;
    if (Object.values(headers).includes(null)) return false;
    return true;
  }, [nameValue, headers]);

  useEffect(() => {
    (async () => {
      await getCatalogsData();
    })();

    if (modifyingItem !== null) {
      setNameValue(modifyingItem.title);
      const headers = modifyingItem.previews.reduce(
        (acc, curr, index) => {
          acc[index + 1] = curr;
          return acc;
        },
        { 1: null, 2: null, 3: null }
      );
      setHeaders(headers);
    }

    return () => {
      setModifyingItem(null);
    };
  }, []);

  return (
    <div className={styles.createThreeHeadersContainer}>
      <Input
        onChange={handleNameInput}
        value={nameValue}
        name='name'
        placeholder='Введите название блока'
        required
      />

      <Collapse defaultActiveKey='1' accordion={true} destroyInactivePanel>
        {[1, 2, 3].map(number => (
          <Collapse.Panel
            key={number}
            header={
              headers[number] === null ? (
                <span className={styles.collapsePanelPlaceholder}>
                  Выберите заголовок {number}
                </span>
              ) : (
                <span>{headers[number].title}</span>
              )
            }
          >
            <CatalogList
              allCatalogs={catalogsData}
              handleSetHeader={handleSetHeader}
              number={number}
              isLoading={catalogsDataIsLoading}
            />
          </Collapse.Panel>
        ))}
      </Collapse>

      {dataIsValid && (
        <Button
          type='primary'
          htmlType='button'
          style={{ marginTop: 'auto', alignSelf: 'end' }}
          onClick={onSubmitHandler}
        >
          Сохранить
        </Button>
      )}
    </div>
  );
};
