import React from 'react';
import { Dropdown, DropdownProps, Menu, MenuProps, Space } from 'antd';
import { DownOutlined } from '@ant-design/icons';

import { MountainIcon } from './MountainIcon';
import { TrianglesIcon } from './TrianglesIcon';

interface SeparatorDropdownProps
  extends Pick<DropdownProps, 'arrow' | 'placement' | 'trigger'> {
  onAddItem: (value: 'mountain' | 'triangles') => any;
  value: 'mountain' | 'triangles';
}

export const SeparatorDropdown: React.FC<SeparatorDropdownProps> = ({
  arrow = true,
  onAddItem,
  value,
  placement = 'bottomRight',
  trigger = ['click'],
}) => {
  const items: MenuProps['items'] = [
    {
      label: <MountainIcon />,
      key: 'mountain',
    },
    {
      label: <TrianglesIcon />,
      key: 'triangles',
    },
  ];

  const onSelect = (e: any) => {
    onAddItem(e.key);
  };

  return (
    <Dropdown
      arrow={arrow}
      overlay={
        <Menu
          style={{ textAlign: 'center' }}
          selectedKeys={[value]}
          onSelect={onSelect}
          selectable
          items={items}
        />
      }
      placement={placement}
      trigger={trigger}
    >
      <Space>
        Тип разделителя
        <DownOutlined />
      </Space>
    </Dropdown>
  );
};
