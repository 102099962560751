export enum ToolbarButtons {
  BOLD = 'bold',
  ITALIC = 'italic',
  UNDERLINE = 'underline',
  STRIKETHROUGH = 'strikethrough',
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  UNORDERED_LIST = 'unorderedlist',
  ORDERED_LIST = 'orderedlist',
  QUOTE = 'quote',
  ANCHOR = 'anchor',
  EMAIL = 'email',
  DASH = 'dash',
  HYPHEN = 'hyphen',
  QUOTES = 'quotes',
  ANCHOR_ID = 'anchor_id',
}
