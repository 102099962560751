import React from 'react';
import { Helmet } from 'react-helmet';
import { Button, Card, Col, Collapse, Row } from 'antd';
import { useForm, FormProvider, FieldValues } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { CatalogAsyncSelect } from 'components/form/selects/CatalogAsyncSelect';
import { ContentField } from 'components/form/Content/ContentField';
import { FormActions } from 'components/Layout/FormActions/FormActions';
import { InputField } from 'components/form/base/InputField';
import { LangSelectSection } from 'components/form/LangSelectSection/LangSelectSection';
import { MainImage } from 'components/form/MainImage/MainImage';
import { Status } from 'components/form/Status/Status';
import { TextAreaField } from 'components/form/base/TextAreaField';
import { TContentBlock } from 'components/form/Content/contentTypes';
import {
  CatalogRegionSelect,
  TRegionType,
} from 'components/form/selects/CatalogRegionSelect';
import { useAppDispatch, useAppSelector } from 'store';
import {
  IArticlesDraftItem,
  IArticlesItem,
} from 'store/slices/articles/interfaces';
import { sourcePreview } from 'store/slices/preview/interfaces';
import { savePreviewData } from 'store/slices/preview/actions';
import { useDeepEffect } from 'utils/useDeepEffect';
import { isEmpty, isString } from 'utils/helpers';
import { TImage } from 'types/image';
import { LangEnumKeys } from 'constants/lang';
import { Statuses } from 'constants/status';

import { mapValues, mapValuesToDraft, validationSchema } from './formUtils';
import { SEO } from '../../../components/form/SEO/SEO';
import { TSEO } from '../../../types/seo';

interface IFormValues extends TSEO {
  category: number | { value: number; label: string }[];
  content: TContentBlock[];
  image: TImage;
  intro?: string;
  lang: LangEnumKeys | { value: LangEnumKeys; label: string };
  name: string;
  region: TRegionType;
  shortDescription: string;
  shortName: string;
  status: Statuses;
  tags: number[] | { value: number; label: string }[];
}

type TProps = {
  initialValues: IFormValues;
  onFinish: (values: IFormValues) => void;
  onSaveDraft: (values: IArticlesDraftItem) => void;
  cancelButtonHandler: () => void;
  isDraft: boolean;
  isLoading: boolean;
  itemId?: number;
};

export const ArticlesForm: React.FC<TProps> = ({
  initialValues,
  onFinish,
  onSaveDraft,
  cancelButtonHandler,
  isDraft,
  isLoading,
  itemId,
}) => {
  const dispatch = useAppDispatch();
  const { b2cUrl } = useAppSelector(state => state.configSlice);
  const [activeCollpaseTabs, setActiveCollpaseTabs] = React.useState([
    'content',
    'description',
    'seo',
    'status',
  ]);
  const resolver = yupResolver(validationSchema);

  const methods = useForm({
    defaultValues: initialValues,
    resolver,
  });

  const currentLanguage = methods.watch('lang.value');

  const handlePreviewClick = async () => {
    const results = await (dispatch(
      savePreviewData({
        source: sourcePreview.articles,
        ...(itemId !== undefined && { sourceId: itemId }),
        data: methods.getValues(),
      })
    ) as Promise<{ payload: { id: number; sysName: string } }>);
    const id = results.payload.id;
    const sysName = results.payload.sysName;
    const linkToB2C =
      currentLanguage === 'en'
        ? `${b2cUrl}/en/articles/preview/${id}/${sysName}`
        : `${b2cUrl}/articles/preview/${id}/${sysName}`;
    window.open(linkToB2C, '_blank');
  };

  const handleSaveAsDraft = () => {
    const formValues = mapValuesToDraft(methods.getValues());
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onSaveDraft(formValues as any);
  };

  const submit = (values: FieldValues) => {
    const preparedValues = mapValues(values) as unknown as IArticlesItem;

    preparedValues.ogTitle = values.ogTitle;
    preparedValues.ogDescription = values.ogDescription;
    preparedValues.ogType = values.ogType;
    preparedValues.ogUrl = values.ogUrl;
    preparedValues.ogSiteName = values.ogSiteName;

    onFinish(preparedValues);
  };

  useDeepEffect(() => {
    methods.reset(initialValues);
  }, [initialValues]);

  useDeepEffect(() => {
    if (!isEmpty(methods.formState.errors)) {
      setActiveCollpaseTabs([
        ...Array.from(
          new Set([
            ...activeCollpaseTabs,
            ...Object.keys(methods.formState.errors),
          ])
        ),
      ]);
    }
  }, [methods.formState.errors]);

  const lang = methods.watch('lang');

  useDeepEffect(() => {
    if (methods.formState.isDirty) {
      methods.reset({ ...methods.getValues(), category: null, tags: [] });
    }
  }, [lang]);

  const langValue = isString(lang) ? lang : lang.value;

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(submit)}
        className='ant-form ant-form-vertical indent-top'
      >
        <Helmet>
          <title>{methods.watch('metaTitle') || 'Default Title'}</title>
          <meta
            name='description'
            content={methods.watch('metaDescription') || 'Default description'}
          />
          <meta name='keywords' content={methods.watch('metaKeywords')} />
          <meta property='og:title' content={methods.watch('ogTitle')} />
          <meta
            property='og:description'
            content={methods.watch('ogDescription') || 'Default OG Description'}
          />
          <meta property='og:image' content={methods.watch('ogImage') || ''} />
          <meta property='og:type' content={methods.watch('ogType') || ''} />
          <meta property='og:url' content={methods.watch('ogUrl') || ''} />
          <meta
            property='og:ogSiteName'
            content={methods.watch('ogSiteName') || ''}
          />
        </Helmet>

        <LangSelectSection />
        <Card title='Общая информация' bordered={false}>
          <Row gutter={[16, 0]}>
            <Col flex={'0 0 100px'}>
              <MainImage name='image' label='Глав. фото' required />
            </Col>
            <Col flex={1} style={{ minWidth: 300 }}>
              <InputField
                name='name'
                label='Название статьи'
                required
                placeholder='Введите название'
                showCount
                maxLength={255}
              />
            </Col>
          </Row>
          <Row gutter={[24, 0]}>
            <Col span={24}>
              <InputField
                name='shortName'
                label='Краткое название статьи'
                required
                placeholder='Введите название'
                showCount
                maxLength={80}
              />
            </Col>
          </Row>

          <Row gutter={[24, 0]}>
            <Col xs={{ span: 24 }} md={{ span: 8 }}>
              <CatalogAsyncSelect
                name='category'
                label='Категория'
                required
                placeholder='Выберите категорию'
                requestParams={{
                  entityType: 'articles',
                  fieldType: 'categories',
                  lang: langValue,
                }}
              />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 16 }}>
              <CatalogAsyncSelect
                name='tags'
                label='Теги'
                placeholder='Выберите теги'
                requestParams={{
                  entityType: 'articles',
                  fieldType: 'tags',
                  lang: langValue,
                }}
                mode='multiple'
              />
            </Col>
          </Row>
          <Row gutter={[24, 0]}>
            <Col xs={{ span: 24 }} md={{ span: 10 }}>
              <CatalogRegionSelect
                name='region'
                label='Регион'
                placeholder='Выберите регион'
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <InputField
                name='shortDescription'
                label='Краткое описание'
                required
                placeholder='Описание'
                showCount
                maxLength={300}
              />
            </Col>
          </Row>
        </Card>

        <Collapse
          bordered={false}
          expandIconPosition='end'
          onChange={value => setActiveCollpaseTabs(value as string[])}
          activeKey={activeCollpaseTabs}
        >
          <Collapse.Panel header='Описание статьи' key='content'>
            <Row>
              <Col span={24}>
                <TextAreaField
                  label='Введение'
                  name='intro'
                  placeholder='Текст'
                  showCount
                  maxLength={700}
                  rows={10}
                />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <ContentField
                  name='content'
                  label='Описание'
                  placeholder='Начните печатать текст'
                  withWidgetsDescription
                  withPreparingForModeration
                  required
                />
              </Col>
            </Row>
          </Collapse.Panel>
          <Collapse.Panel header='SEO' key='seo'>
            <SEO />
          </Collapse.Panel>
          <Collapse.Panel header='Публикация' key='status'>
            <Status status={initialValues?.status} isStatusesLimited />
          </Collapse.Panel>
        </Collapse>
        <FormActions>
          <Button onClick={cancelButtonHandler} disabled={isLoading}>
            Отмена
          </Button>
          {isDraft ? (
            <Button onClick={handleSaveAsDraft} loading={isLoading}>
              Сохранить как черновик
            </Button>
          ) : null}
          <Button onClick={handlePreviewClick} disabled={isLoading}>
            Предпросмотр
          </Button>
          <Button type='primary' htmlType='submit' loading={isLoading}>
            Сохранить
          </Button>
        </FormActions>
      </form>
    </FormProvider>
  );
};
