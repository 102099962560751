import { TImage } from 'types/image';

export enum ContentTypeEnum {
  collage = 'collage',
  gallery = 'gallery',
  geoPosition = 'geoPosition',
  htmlCode = 'htmlCode',
  image = 'image',
  quote = 'quote',
  reference = 'reference',
  separator = 'separator',
  text = 'text',
  video = 'video',
  widget = 'widget',
}

export enum WidgetTypeEnum {
  events = 'events',
  attractions = 'attractions',
  articles = 'articles',
  manual = 'manual',
  resorts = 'resorts',
  hotels = 'hotels',
  restaurants = 'restaurants',
  news = 'news',
  routes = 'routes',
}

export type WidgetType = `${WidgetTypeEnum}`;

export const widgetTypeNamesHash = {
  [WidgetTypeEnum.events]: 'События',
  [WidgetTypeEnum.attractions]: 'Достопримечательности',
  [WidgetTypeEnum.articles]: 'Статьи',
  [WidgetTypeEnum.manual]: 'Геометка',
  [WidgetTypeEnum.resorts]: 'Курорты',
  [WidgetTypeEnum.hotels]: 'Отели',
  [WidgetTypeEnum.restaurants]: 'Кафе и рестораны',
  [WidgetTypeEnum.news]: 'Новости',
  [WidgetTypeEnum.routes]: 'Маршруты',
};

export type TTextBlock = {
  type: ContentTypeEnum.text;
  text: string;
};

type TImageBlock = {
  type: ContentTypeEnum.image;
  image: TImage;
};

type TGalleryBlock = {
  type: ContentTypeEnum.gallery;
  gallery: TImage[];
};

type TVideoBlock = {
  type: ContentTypeEnum.video;
  video: any;
};

type TWidgetBlock = {
  type: ContentTypeEnum.widget;
  widget: any;
};

type TManualBlock = {
  type: ContentTypeEnum.geoPosition;
  widget: any;
};

type TCollageBlock = {
  type: ContentTypeEnum.collage;
  collage: any;
};

type TQuoteBlock = {
  type: ContentTypeEnum.quote;
  collage: any;
};

type TSeparatorBlock = {
  type: ContentTypeEnum.separator;
  separator: 'mountain' | 'triangles';
};

type THtmlCodeBlock = {
  type: ContentTypeEnum.htmlCode;
  htmlCode: any;
};

type TReferenceBlock = {
  type: ContentTypeEnum.reference;
  reference: {
    title: string;
    subtitle: string;
  };
};

export type TContentBlock =
  | TTextBlock
  | TImageBlock
  | TGalleryBlock
  | TVideoBlock
  | TWidgetBlock
  | TManualBlock
  | TCollageBlock
  | TQuoteBlock
  | TSeparatorBlock
  | THtmlCodeBlock
  | TReferenceBlock;

export type ContentType = `${ContentTypeEnum}`;
