import { Alert, Col, Row } from 'antd';
import React from 'react';

import { TextAreaField } from '../base/TextAreaField';
import styles from './SEO.module.less';
import { UploadSingleFile } from '../UploadSingleFile/UploadSingleFile';
import { MAX_IMAGE_FILE_SIZE } from '../../../constants/upload';

interface SEOProps {
  isShowAlert?: boolean;
}

export const SEO = ({ isShowAlert = false }: SEOProps) => {
  return (
    <>
      {isShowAlert && (
        <Alert
          type='info'
          showIcon
          message='Если поля не заполнены, то контент заполняется по шаблону'
          className={styles.alert}
        />
      )}
      <Row gutter={[24, 0]}>
        <Col sm={{ span: 12 }} xs={{ span: 24 }}>
          <TextAreaField
            required
            name='metaTitle'
            label='metaTitle'
            showCount
            maxLength={100}
          />
        </Col>
        <Col sm={{ span: 12 }} xs={{ span: 24 }}>
          <TextAreaField
            required
            name='metaDescription'
            label='metaDescription'
            showCount
            maxLength={150}
          />
        </Col>
        <Col sm={{ span: 12 }} xs={{ span: 24 }}>
          <TextAreaField
            required
            name='ogTitle'
            label='ogTitle'
            showCount
            maxLength={100}
          />
        </Col>
        <Col sm={{ span: 12 }} xs={{ span: 24 }}>
          <TextAreaField
            required
            name='ogDescription'
            label='ogDescription'
            showCount
            maxLength={150}
          />
        </Col>
        <Col sm={{ span: 12 }} xs={{ span: 24 }}>
          <TextAreaField
            required
            name='ogType'
            label='ogType'
            showCount
            maxLength={150}
            disabled={false}
          />
        </Col>
        <Col sm={{ span: 12 }} xs={{ span: 24 }}>
          <TextAreaField
            required
            name='ogUrl'
            label='ogUrl'
            showCount
            maxLength={150}
            disabled={false}
          />
        </Col>
        <Col sm={{ span: 12 }} xs={{ span: 24 }}>
          <TextAreaField
            required
            disabled={false}
            name='ogSiteName'
            label='ogSiteName'
            showCount
            maxLength={150}
          />
        </Col>
        <Col sm={{ span: 12 }} xs={{ span: 24 }}>
          Изображение для SEO
          <UploadSingleFile
            textInfo={true}
            validWith={1200}
            validHeight={630}
            buttonTitle={'Загрузить изображение'}
            maxFileSize={MAX_IMAGE_FILE_SIZE}
            name={'ogImage'}
          />
        </Col>
        <Col span={24}>
          <TextAreaField
            name='metaKeywords'
            label='Ключевые слова'
            showCount
            maxLength={150}
          />
        </Col>
      </Row>
    </>
  );
};
