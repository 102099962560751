import React, { useEffect } from 'react';
import classNames from 'classnames';
import { Form, FormItemProps } from 'antd';
import { Controller, useFormContext } from 'react-hook-form';

import { AntUpload } from '../ImageUpload';
import { DefaultTooltip } from './components/DefaultTooltip';

type IProps = Omit<FormItemProps, 'name'> & {
  name: string;
  aspectRatio?: number;
  watchAspectRatio?: boolean;
  customTooltip?: React.ReactNode;
  isNoInputs?: boolean;
  modalIsOpen?: boolean;
  setModalIsOpen?: (value: boolean) => void;
  className?: string;
};

export const MainImage: React.FC<IProps> = ({
  name,
  label,
  required,
  aspectRatio,
  watchAspectRatio,
  customTooltip,
  isNoInputs,
  modalIsOpen,
  setModalIsOpen,
  className,
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        return (
          <div
            className={classNames(
              'main-image image-uploader__images',
              className,
              {
                'image-uploader__error': fieldState.error,
              }
            )}
            ref={field.ref}
            tabIndex={0}
          >
            <div>
              <Form.Item
                label={label}
                required={required}
                tooltip={customTooltip || <DefaultTooltip />}
              >
                <AntUpload
                  showError={Boolean(fieldState.error)}
                  onChange={imageValues => {
                    field.onChange(imageValues);
                  }}
                  onDrop={file => field.onChange({ file })}
                  value={field.value}
                  aspectRatio={aspectRatio}
                  watchAspectRatio={watchAspectRatio}
                  isNoInputs={isNoInputs}
                  modalIsOpen={modalIsOpen}
                  setModalIsOpen={setModalIsOpen}
                />
              </Form.Item>
            </div>
          </div>
        );
      }}
    />
  );
};
