import { RcFile, UploadChangeParam } from 'antd/lib/upload';

import { urls } from 'store/api';

import { isEmpty, omit, partition } from './helpers';
import { apiClient } from './http';

function imageFilesUpload(files) {
  if (isEmpty(files)) {
    return Promise.resolve([]);
  }

  const formData = new window.FormData();

  files.forEach(file => {
    formData.append('image', file);
  });

  return apiClient
    .post(urls.api.upload.image.post, formData)
    .then(res => res.data);
}

function materialUpload(material: RcFile) {
  if (isEmpty(material)) {
    return Promise.resolve([]);
  }

  const formData = new window.FormData();

  formData.append('material', material);
  return apiClient
    .post(urls.api.upload.material.post, formData)
    .then(res => res.data);
}

function audioFilesUpload(files) {
  if (isEmpty(files)) {
    return Promise.resolve([]);
  }

  const formData = new window.FormData();

  files.forEach(file => {
    formData.append('audio', file);
  });
  return apiClient.post(urls.api.upload.audio.post, formData).then(res => {
    const data = res.data;
    return {
      path: data.name,
      realName: data.realName,
    };
  });
}

export const uploadImage = (data, { fileLoader = imageFilesUpload } = {}) => {
  if (data && data.file) {
    const newData = { ...data };
    return fileLoader([newData.file]).then(res => {
      delete newData.file;

      return { ...newData, path: res.name };
    });
  } else {
    return Promise.resolve(data);
  }
};

export const uploadImageArray = (
  array,
  { fileLoader = imageFilesUpload } = {}
) => {
  if (!array || !Array.isArray(array)) {
    return Promise.resolve([]);
  }
  array = array.filter(i => i);
  if (!array.length) {
    return Promise.resolve([]);
  }

  const [newFiles, oldFiles] = partition(array, ({ file }) => file);

  return Promise.all(
    newFiles.map(item => {
      return fileLoader([item.file]);
    })
  ).then(uploadData =>
    oldFiles.concat(
      uploadData.map((item, index) => {
        delete newFiles[index].file;

        return {
          ...newFiles[index],
          path: item.name,
        };
      })
    )
  );
};

export const uploadContent = content => {
  if (!content || !Array.isArray(content)) {
    return Promise.resolve([]);
  }
  const promises = content.map(async contentItem => {
    switch (contentItem.type) {
      case 'image':
        contentItem.image = await uploadImage(contentItem.image);
        return contentItem;
      case 'collage':
        contentItem.collage.images = await uploadImageArray(
          contentItem.collage.images
        );
        return contentItem;
      case 'gallery':
        contentItem.gallery = await uploadImageArray(contentItem.gallery);
        return contentItem;
      case 'geoPosition': {
        contentItem.geoPosition.image = await uploadImage(
          contentItem.geoPosition.image
        );
        return contentItem;
      }
      case 'quote': {
        contentItem.quote.image = await uploadImage(contentItem.quote.image);
        return contentItem;
      }
      default:
        return contentItem;
    }
  });

  return Promise.all(promises);
};

export const uploadAudio = (data, { fileLoader = audioFilesUpload } = {}) => {
  if (data && data.file?.originFileObj) {
    const newData = { ...data };

    return fileLoader([newData.file.originFileObj]).then(res => {
      return {
        ...res,
      };
    });
  } else {
    return Promise.reject('Ошибка при загрузке файла');
  }
};

export interface UploadMaterialResponse {
  path: string;
  baseUrl?: string;
  fileList: UploadChangeParam['fileList'];
  size: number;
}

export const uploadMaterial = (
  data: UploadChangeParam,
  { fileLoader = materialUpload } = {}
): Promise<UploadMaterialResponse> => {
  if (data && data.file?.originFileObj) {
    return fileLoader(data.file.originFileObj).then(res => {
      return {
        ...omit(data, 'file'),
        path: res.name,
        size: res.size,
      };
    });
  } else {
    return Promise.resolve(data);
  }
};
