import { useContext, useEffect } from 'react';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';

type BlockerFunction = (
  nextLocation:
    | string
    | Partial<{ pathname: string; search?: string; hash?: string }>
) => boolean;

export const useBlocker = (blocker: BlockerFunction, when = true) => {
  const { navigator } = useContext(NavigationContext);

  useEffect(() => {
    if (!when) return;

    const push = navigator.push;

    navigator.push = (...args: Parameters<typeof push>) => {
      const [nextLocation] = args;

      const location =
        typeof nextLocation === 'string'
          ? nextLocation
          : nextLocation.pathname || '/';

      if (blocker(location)) return;

      push(...args);
    };

    return () => {
      navigator.push = push;
    };
  }, [blocker, navigator, when]);
};
