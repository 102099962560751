import React from 'react';

import { MainB2C } from './MainB2C';

export const mainB2CRoutes = {
  path: '/mainB2C',
  children: [
    {
      path: '',
      element: <MainB2C />,
    },
  ],
};
