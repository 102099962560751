import MediumEditor from 'medium-editor';

import { createButton } from '../helpers';

function getSelection() {
  return window.getSelection();
}

export const AnchorId = MediumEditor.extensions.anchor.extend({
  ...createButton('anchor_id', 'Имя якоря', 'anchor_id'),
  aria: 'Имя якоря',
  placeholderText: 'Введите имя якоря',
  formSaveLabel: 'Сохранить',
  formCloseLabel: 'Отменить',
  checkState: function () {
    const text = getSelection();
    const nodeId = text.anchorNode.parentNode.getAttribute('id');

    if (nodeId) {
      this.setActive();
    } else {
      this.setInactive();
    }
  },
  handleClick: function (event) {
    event.preventDefault();
    event.stopPropagation();
    const text = getSelection();

    if (this.isActive()) {
      text.anchorNode.parentNode.removeAttribute('id');
      this.setInactive();
      this.base.checkContentChanged();
    } else {
      this.showForm();
      this.base.checkContentChanged();
    }
  },
  /**
   * Метод showForm взят из https://github.com/yabwe/medium-editor/blob/master/src/js/extensions/anchor.js
   * без него невозможно корректно обработать handleClick
   */
  showForm: function (opts) {
    var input = this.getInput(),
      targetCheckbox = this.getAnchorTargetCheckbox(),
      buttonCheckbox = this.getAnchorButtonCheckbox();

    opts = opts || { value: '' };
    // TODO: This is for backwards compatability
    // We don't need to support the 'string' argument in 6.0.0
    if (typeof opts === 'string') {
      opts = {
        value: opts,
      };
    }

    this.base.saveSelection();
    this.hideToolbarDefaultActions();
    MediumEditor.extensions.form.prototype.showForm.apply(this);
    this.setToolbarPosition();

    input.value = opts.value;
    input.focus();

    // If we have a target checkbox, we want it to be checked/unchecked
    // based on whether the existing link has target=_blank
    if (targetCheckbox) {
      targetCheckbox.checked = opts.target === '_blank';
    }

    // If we have a custom class checkbox, we want it to be checked/unchecked
    // based on whether an existing link already has the class
    if (buttonCheckbox) {
      var classList = opts.buttonClass ? opts.buttonClass.split(' ') : [];
      buttonCheckbox.checked = classList.indexOf(this.customClassOption) !== -1;
    }
  },
  doFormSave: function () {
    const text = getSelection();
    const opts = this.getFormOpts();
    this.completeFormSave({
      id: opts.value,
    });

    text.focusNode.parentNode.setAttribute('id', opts.value);
    this.setActive();
  },
});
