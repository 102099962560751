import { Roles } from 'constants/roles';
import { attractionsRoutes } from 'pages/Attractions/routes';
import { resortsRoutes } from 'pages/Resorts/routes';
import { hotelsRoutes } from 'pages/Hotels/routes';
import { restaurantsRoutes } from 'pages/Restaurants/routes';
import { infrastructuresRoutes } from 'pages/Infrastructures/routes';
import { newsRoutes } from 'pages/News/routes';
import { articlesRoutes } from 'pages/Articles/routes';
import { touristRoutesRoutes } from 'pages/TouristRoutes/routes';
import { eventsRoutes } from 'pages/Events/routes';
import { regionsRoutes } from 'pages/Regions/routes';
import { reviewsRoutes } from 'pages/Reviews/routes';
import { materialsRoutes } from 'pages/Materials/routes';
import { faqRoutes } from 'pages/FAQ/routes';
import { investmentProjectsRoutes } from 'pages/InvestmentProjects/routes';
import { investmentPassportsRoutes } from 'pages/InvestmentPassports/routes';
import { investInfrastructuresRoutes } from 'pages/InvestmentInfrastructures/routes';
import { supportMeasuresRoutes } from 'pages/SupportMeasures/routes';
import { projectsRoutes } from 'pages/Projects/routes';
import { applicationsFormRoutes } from 'pages/ApplicationsFromForms/routes';
import { applicationsRoutes } from 'pages/Applications/routes';
import { reportsRoutes } from 'pages/Reports/routes';
import { organizationsRoutes } from 'pages/Organizations/routes';
import { touristRoutes } from 'pages/Tourists/routes';
import { businessRoutes } from 'pages/Business/routes';
import { adminRoutes } from 'pages/Admin/routes';
import { purchasesRoutes } from 'pages/Purchases/routes';
import { salesRoutes } from 'pages/Sales/routes';
import { rentalsRoutes } from 'pages/Rentals/routes';
import { signedAgreementsRoutes } from 'pages/SignedAgreements/routes';
import { purchasePlansRoutes } from 'pages/PurchasePlans/routes';
import { regulationsRoutes } from 'pages/Regulations/routes';
import { vacanciesRoutes } from 'pages/Vacancies/routes';
import { contractExchangeRoutes } from 'pages/ContractExchange/routes';
import { priorityIndustryRoutes } from 'pages/PriorityIndustry/routes';
import { openingInfoRoutes } from 'pages/OpeningInfo/routes';
import { mainBusinessRoutes } from 'pages/MainBusiness/routes';
import { mainTourismRoutes } from 'pages/MainTourism/routes';
import { catalogRoutes } from 'pages/Catalog/routes';
import { menuRoutes } from 'pages/Menu/routes';
import { attractionsSampleRoutes } from 'pages/AttractionsSample/routes';
import { resortsSampleRoutes } from 'pages/ResortsSample/routes';
import { hotelsSampleRoutes } from 'pages/HotelsSample/routes';
import { eventsSampleRoutes } from 'pages/EventsSample/routes';
import { routesSampleRoutes } from 'pages/RoutesSample/routes';
import { restaurantsSampleRoutes } from 'pages/RestaurantsSample/routes';
import { alertB2BRoutes, alertB2CRoutes } from 'pages/Alert/routes';
import { activitiesRoutes } from 'pages/Activities/routes';
import { currentResidentsRoutes } from 'pages/CurrentResidents/routes';
import { investmentSolutionsRoutes } from 'pages/InvestmentSolutions/routes';
import { aboutRegionRoutes } from 'pages/AboutRegion/routes';
import { mainB2CRoutes } from 'pages/MainB2C/routes';

export const APP_ROUTES_BY_ROLES = {
  [Roles.Admin]: [
    mainB2CRoutes,
    attractionsRoutes,
    resortsRoutes,
    hotelsRoutes,
    restaurantsRoutes,
    infrastructuresRoutes,
    newsRoutes,
    articlesRoutes,
    touristRoutesRoutes,
    eventsRoutes,
    regionsRoutes,
    reviewsRoutes,
    materialsRoutes,
    faqRoutes,
    investmentProjectsRoutes,
    investmentPassportsRoutes,
    investInfrastructuresRoutes,
    supportMeasuresRoutes,
    projectsRoutes,
    applicationsRoutes,
    reportsRoutes,
    organizationsRoutes,
    touristRoutes,
    businessRoutes,
    adminRoutes,
    applicationsFormRoutes,
    purchasesRoutes,
    salesRoutes,
    rentalsRoutes,
    signedAgreementsRoutes,
    purchasePlansRoutes,
    regulationsRoutes,
    vacanciesRoutes,
    contractExchangeRoutes,
    priorityIndustryRoutes,
    openingInfoRoutes,
    catalogRoutes,
    menuRoutes,
    mainBusinessRoutes,
    mainTourismRoutes,
    attractionsSampleRoutes,
    resortsSampleRoutes,
    hotelsSampleRoutes,
    eventsSampleRoutes,
    restaurantsSampleRoutes,
    routesSampleRoutes,
    alertB2BRoutes,
    alertB2CRoutes,
    activitiesRoutes,
    currentResidentsRoutes,
    investmentSolutionsRoutes,
    aboutRegionRoutes,
  ],
  [Roles.AdminBuisness]: [
    newsRoutes,
    materialsRoutes,
    faqRoutes,
    investmentProjectsRoutes,
    investmentPassportsRoutes,
    investInfrastructuresRoutes,
    supportMeasuresRoutes,
    projectsRoutes,
    applicationsFormRoutes,
    applicationsRoutes,
    reportsRoutes,
    organizationsRoutes,
    businessRoutes,
    purchasesRoutes,
    salesRoutes,
    rentalsRoutes,
    signedAgreementsRoutes,
    purchasePlansRoutes,
    regulationsRoutes,
    vacanciesRoutes,
    contractExchangeRoutes,
    priorityIndustryRoutes,
    openingInfoRoutes,
    catalogRoutes,
    mainBusinessRoutes,
    alertB2BRoutes,
    alertB2CRoutes,
    currentResidentsRoutes,
    investmentSolutionsRoutes,
  ],
  [Roles.AdminContent]: [
    attractionsRoutes,
    resortsRoutes,
    hotelsRoutes,
    restaurantsRoutes,
    infrastructuresRoutes,
    newsRoutes,
    articlesRoutes,
    touristRoutesRoutes,
    eventsRoutes,
    regionsRoutes,
    reviewsRoutes,
    faqRoutes,
    touristRoutes,
    catalogRoutes,
    mainTourismRoutes,
    attractionsSampleRoutes,
    resortsSampleRoutes,
    hotelsSampleRoutes,
    eventsSampleRoutes,
    restaurantsSampleRoutes,
    routesSampleRoutes,
    aboutRegionRoutes,
  ],
  [Roles.AdminPress]: [newsRoutes, articlesRoutes, catalogRoutes],
  [Roles.AdminSales]: [
    purchasesRoutes,
    salesRoutes,
    rentalsRoutes,
    signedAgreementsRoutes,
    purchasePlansRoutes,
    regulationsRoutes,
    catalogRoutes,
  ],
  [Roles.AdminInvestments]: [
    investmentProjectsRoutes,
    investmentPassportsRoutes,
    investInfrastructuresRoutes,
    supportMeasuresRoutes,
    projectsRoutes,
    applicationsFormRoutes,
    organizationsRoutes,
    businessRoutes,
    priorityIndustryRoutes,
    catalogRoutes,
    investmentSolutionsRoutes,
  ],
  [Roles.AdminOez]: [
    applicationsRoutes,
    investmentProjectsRoutes,
    investInfrastructuresRoutes,
    applicationsFormRoutes,
    reportsRoutes,
    organizationsRoutes,
    businessRoutes,
    catalogRoutes,
    currentResidentsRoutes,
    investmentSolutionsRoutes,
  ],
  [Roles.Guest]: [],
  [Roles.Unauthorized]: [],
};

export const ROUTES_PATHS_BY_ROLES = Object.fromEntries(
  Object.entries(APP_ROUTES_BY_ROLES).map(([key, value]) => {
    return [key, value.map(item => item.path)];
  })
);
