// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CropperModal-module__inputs--rLw_4 {\n  flex: 1;\n}\n@media (max-width: 800px) {\n  .CropperModal-module__inputs--rLw_4 {\n    width: 100%;\n  }\n}\n.CropperModal-module__image--o3cQg {\n  position: relative;\n  width: 472px;\n  margin-left: 24px;\n}\n@media (max-width: 800px) {\n  .CropperModal-module__image--o3cQg {\n    width: 100%;\n    margin-left: 0;\n    padding: 0 24px;\n  }\n}\n.CropperModal-module__image__in--mYOyg {\n  position: relative;\n  margin-top: 8px;\n  padding: 50%;\n}\n.CropperModal-module__image__wrapper--J62Ds {\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n}\n.CropperModal-module__imageSettimg--NxTN3 [class~='ant-form-item-control-input-content'] {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  gap: 24px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/form/CropperModal/CropperModal.module.less"],"names":[],"mappings":"AAAA;EACE,OAAA;AACF;AAAE;EAAA;IACE,WAAA;EAGF;AACF;AADA;EACE,kBAAA;EACA,YAAA;EACA,iBAAA;AAGF;AAFE;EAAA;IACE,WAAA;IACA,cAAA;IACA,eAAA;EAKF;AACF;AAJE;EACE,kBAAA;EACA,eAAA;EACA,YAAA;AAMJ;AAJE;EACE,kBAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;AAMJ;AAFA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,SAAA;AAIF","sourcesContent":[".inputs {\n  flex: 1;\n  @media (max-width: 800px) {\n    width: 100%;\n  }\n}\n.image {\n  position: relative;\n  width: 472px;\n  margin-left: 24px;\n  @media (max-width: 800px) {\n    width: 100%;\n    margin-left: 0;\n    padding: 0 24px;\n  }\n  &__in {\n    position: relative;\n    margin-top: 8px;\n    padding: 50%;\n  }\n  &__wrapper {\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n  }\n}\n\n.imageSettimg [class~='ant-form-item-control-input-content'] {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  gap: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputs": "CropperModal-module__inputs--rLw_4",
	"image": "CropperModal-module__image--o3cQg",
	"image__in": "CropperModal-module__image__in--mYOyg",
	"image__wrapper": "CropperModal-module__image__wrapper--J62Ds",
	"imageSettimg": "CropperModal-module__imageSettimg--NxTN3"
};
export default ___CSS_LOADER_EXPORT___;
