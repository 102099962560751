import * as Yup from 'yup';
import { FieldValues } from 'react-hook-form';

import {
  mapContent,
  mapContentToForm,
  mapImage,
  mapImageForSeo,
  mapRegion,
  mapSelect,
  mapValuesToRegion,
  mapValuesToSelect,
} from 'utils/mappings';
import { contentSchema, imageSchema } from 'utils/yup';
import { prepareContent } from 'components/form/Content/utils';
import { StatusesEnum } from 'constants/status';
import { LangEnum, LangEnumKeys } from 'constants/lang';

export const mapValuesToForm = values => {
  return {
    ...values,
    category: values?.categoryData
      ? mapValuesToSelect(values?.categoryData)
      : null,
    content: prepareContent(mapContentToForm(values.content || [])),
    intro: values?.intro || '',
    lang: values?.lang || LangEnum.ru,
    name: values?.name || '',
    region: values?.region ? mapValuesToRegion(values?.region) : null,
    shortDescription: values.shortDescription || '',
    status:
      values?.status === StatusesEnum.DRAFT
        ? StatusesEnum.PUBLISHED
        : values?.status,
    tags: values?.tagsData ? mapValuesToSelect(values?.tagsData) : [],
    ogTitle: values.ogTitle || '',
    ogDescription: values.ogDescription || '',
    metaDescription: values.metaDescription || '',
    metaKeywords: values.metaKeywords || '',
    metaTitle: values.metaTitle || '',
    ogType: values.ogType || null,
    ogUrl: values.ogUrl || null,
    ogSiteName: values.ogSiteName || null,
  };
};

export const validationSchema = Yup.object().shape({
  category: Yup.mixed().required(),
  content: contentSchema,
  image: imageSchema,
  intro: Yup.string()
    .notRequired()
    .trim()
    .max(700, 'Введите не более 600 символов'),
  name: Yup.string()
    .trim()
    .min(1, 'Введите от 1 до 255 символов')
    .max(255, 'Введите от 1 до 255 символов')
    .required('Это поле необходимо заполнить'),
  region: Yup.mixed().notRequired(),
  shortDescription: Yup.string()
    .trim()
    .min(1, 'Введите от 1 до 300 символов')
    .max(300, 'Введите от 1 до 300 символов')
    .required(),
  shortName: Yup.string()
    .trim()
    .min(1, 'Введите от 1 до 80 символов')
    .max(80, 'Введите от 1 до 80 символов')
    .required(),
  status: Yup.mixed().required(),
  tags: Yup.array().max(10, 'Выберите не более ${max} тегов'),
  ogTitle: Yup.string()
    .trim()
    .min(1, 'Введите от 1 до 300 символов')
    .max(300, 'Введите от 1 до 300 символов')
    .required(),
  ogDescription: Yup.string()
    .trim()
    .min(1, 'Введите от 1 до 300 символов')
    .max(300, 'Введите от 1 до 300 символов')
    .required(),
  metaTitle: Yup.string()
    .trim()
    .min(1, 'Введите от 1 до 300 символов')
    .max(300, 'Введите от 1 до 300 символов')
    .required(),
  metaDescription: Yup.string()
    .trim()
    .min(1, 'Введите от 1 до 300 символов')
    .max(300, 'Введите от 1 до 300 символов')
    .required(),
  ogUrl: Yup.string()
    .trim()
    .min(1, 'Введите от 1 до 300 символов')
    .max(300, 'Введите от 1 до 300 символов')
    .required(),
  ogSiteName: Yup.string()
    .trim()
    .min(1, 'Введите от 1 до 300 символов')
    .max(300, 'Введите от 1 до 300 символов')
    .required(),
});

export const mapValues = (values: FieldValues) => {
  return {
    category: mapSelect(values.category),
    content: mapContent(values.content),
    image: mapImage(values.image),
    intro: values?.intro?.trim() || '',
    lang: mapSelect(values.lang) as LangEnumKeys,
    name: values.name.trim(),
    region: mapRegion(values.region),
    shortDescription: values.shortDescription.trim(),
    shortName: values.shortName.trim(),
    status: mapSelect(values.status),
    tags: mapSelect(values.tags),
    ogImage: values.ogImage ? mapImageForSeo(mapImage(values.ogImage)) : null,
    ogDescription: values.ogDescription || null,
    ogTitle: values.ogTitle || null,
    metaDescription: values.metaDescription || null,
    metaKeywords: values.metaKeywords || null,
    metaTitle: values.metaTitle || null,
    ogType: values.ogType || null,
    ogUrl: values.ogUrl || null,
    ogSiteName: values.ogSiteName || null,
  };
};

export const mapValuesToDraft = values => {
  return {
    category: values.category ? mapSelect(values.category) : null,
    content: values.content?.length ? mapContent(values.content) : [],
    image: values.image ? mapImage(values.image) : null,
    intro: values?.intro?.trim() || '',
    lang: mapSelect(values.lang) as LangEnumKeys,
    name: values.name?.trim() || null,
    region: values.region ? mapRegion(values.region) : null,
    shortDescription: values.shortDescription?.trim() || null,
    shortName: values.shortName?.trim() || '',
    tags: values.tags?.length ? mapSelect(values.tags) : [],
    ogImage: values.ogImage ? mapImageForSeo(mapImage(values.ogImage)) : null,
    ogDescription: values.ogDescription || null,
    ogTitle: values.ogTitle || null,
    metaDescription: values.metaDescription || null,
    metaKeywords: values.metaKeywords || null,
    metaTitle: values.metaTitle || null,
    ogType: values.ogType || null,
    ogUrl: values.ogUrl || null,
    ogSiteName: values.ogSiteName || null,
  };
};
