import React from 'react';

interface SeparatorImageProps {
  variant: 'montain' | 'triangles';
}

export const SeparatorImage: React.FC<SeparatorImageProps> = ({ variant }) => {
  if (variant === 'triangles') {
    return (
      <svg
        width='100%'
        height='100%'
        viewBox='0 0 700 13'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g opacity='0.25'>
          <path
            d='M336.071 11.7793H325.729L330.9 2.78543L336.071 11.7793Z'
            stroke='#0161FD'
            strokeWidth='2'
          />
          <path
            d='M353.872 11.7793H343.529L348.7 2.78543L353.872 11.7793Z'
            stroke='#0161FD'
            strokeWidth='2'
          />
          <path
            d='M371.671 11.7718H361.329L366.5 2.78448L371.671 11.7718Z'
            stroke='#0161FD'
            strokeWidth='2'
          />
          <line x1='313' y1='8.2793' y2='8.2793' stroke='#0161FD' />
          <line x1='700' y1='8.2793' x2='386' y2='8.2793' stroke='#0161FD' />
        </g>
      </svg>
    );
  }

  return (
    <svg
      width='100%'
      height='100%'
      viewBox='0 0 701 23'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g opacity='0.25'>
        <path
          d='M337.218 21.6931H367.714L352.466 1.69336L345.451 10.9324'
          stroke='#0161FD'
          strokeWidth='2'
          strokeMiterlimit='10'
        />
        <path
          d='M342.773 7.56299L332 21.6932H353.545L342.773 7.56299Z'
          stroke='#0161FD'
          strokeWidth='2'
          strokeMiterlimit='10'
        />
        <line x1='325' y1='12.1934' y2='12.1934' stroke='#0161FD' />
        <line x1='701' y1='12.1934' x2='376' y2='12.1934' stroke='#0161FD' />
      </g>
    </svg>
  );
};
