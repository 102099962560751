// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Quote-module__authorInfo--F85DY {\n  display: grid;\n  grid-template-columns: auto 1fr;\n  column-gap: 20px;\n}\n.Quote-module__image--CYefJ [class~='upload__overlay-container'],\n.Quote-module__image--CYefJ [class~='ant-upload-select'] {\n  width: 118px;\n  height: 118px;\n}\n.Quote-module__image--CYefJ img {\n  width: 116px !important;\n  height: 116px !important;\n}\n", "",{"version":3,"sources":["webpack://./src/components/form/Content/Quote/Quote.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,+BAAA;EACA,gBAAA;AACF;AAEA;;EAEE,YAAA;EACA,aAAA;AAAF;AAGA;EACE,uBAAA;EACA,wBAAA;AADF","sourcesContent":[".authorInfo {\n  display: grid;\n  grid-template-columns: auto 1fr;\n  column-gap: 20px;\n}\n\n.image [class~='upload__overlay-container'],\n.image [class~='ant-upload-select'] {\n  width: 118px;\n  height: 118px;\n}\n\n.image img {\n  width: 116px !important;\n  height: 116px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"authorInfo": "Quote-module__authorInfo--F85DY",
	"image": "Quote-module__image--CYefJ"
};
export default ___CSS_LOADER_EXPORT___;
